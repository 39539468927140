import * as THREE from "three";
import { mergeVertices } from "three/examples/jsm/utils/BufferGeometryUtils";
//eventually create Constants module
const EonShapeConstants = {
  GUMS: 1,
  TOOTH_START: 2,
  ATTACHMENTS_START: 45,
};

export const TeethNumberingData = [
  { name: "upper_r_8", number: "1", displayName: "Ur8" },
  { name: "upper_r_7", number: "2", displayName: "Ur7" },
  { name: "upper_r_6", number: "3", displayName: "Ur6" },
  { name: "upper_r_5", number: "4", displayName: "Ur5" },
  { name: "upper_r_4", number: "5", displayName: "Ur4" },
  { name: "upper_r_3", number: "6", displayName: "Ur3" },
  { name: "upper_r_2", number: "7", displayName: "Ur2" },
  { name: "upper_r_1", number: "8", displayName: "Ur1" },
  { name: "upper_l_1", number: "9", displayName: "Ul1" },
  { name: "upper_l_2", number: "10", displayName: "Ul2" },
  { name: "upper_l_3", number: "11", displayName: "Ul3" },
  { name: "upper_l_4", number: "12", displayName: "Ul4" },
  { name: "upper_l_5", number: "13", displayName: "Ul5" },
  { name: "upper_l_6", number: "14", displayName: "Ul6" },
  { name: "upper_l_7", number: "15", displayName: "Ul7" },
  { name: "upper_l_8", number: "16", displayName: "Ul8" },
  { name: "lower_l_8", number: "17", displayName: "Ll8" },
  { name: "lower_l_7", number: "18", displayName: "Ll7" },
  { name: "lower_l_6", number: "19", displayName: "Ll6" },
  { name: "lower_l_5", number: "20", displayName: "Ll5" },
  { name: "lower_l_4", number: "21", displayName: "Ll4" },
  { name: "lower_l_3", number: "22", displayName: "Ll3" },
  { name: "lower_l_2", number: "23", displayName: "Ll2" },
  { name: "lower_l_1", number: "24", displayName: "Ll1" },
  { name: "lower_r_1", number: "25", displayName: "Lr1" },
  { name: "lower_r_2", number: "26", displayName: "Lr2" },
  { name: "lower_r_3", number: "27", displayName: "Lr3" },
  { name: "lower_r_4", number: "28", displayName: "Lr4" },
  { name: "lower_r_5", number: "29", displayName: "Lr5" },
  { name: "lower_r_6", number: "30", displayName: "Lr6" },
  { name: "lower_r_7", number: "31", displayName: "Lr7" },
  { name: "lower_r_8", number: "32", displayName: "Lr8" },
];

let getTeethNumberingByNumber = function (n) {
  return TeethNumberingData.find((a) => a.number == n);
};

let getTeethNumberingByName = function (name) {
  return TeethNumberingData.find((a) => a.name == name);
};

export const TeethNumbering = {
  byNumber: getTeethNumberingByNumber,
  byName: getTeethNumberingByName,
};

//Mocking threejs classes
let BufferGeometry = function () {
  this.attributes = {};

  this.addAttribute = function (name, attr) {
    //console.error(name, attr);
    //console.error(this.attributes)
    this.attributes[name] = attr;
  };

  this.setExtracted = function (extracted) {
    this.extracted = true;
  };
  this.isExtracted = function () {
    return this.extracted;
  };
};

let BufferAttribute = function (v, l) {
  this.v = v;
  this.l = l;
};

let Mesh = function (geom, mtl) {
  this.geom = geom;
  this.mtl = mtl;
  this.parts = [];
  this.name = "";

  this.add = function (part) {
    //console.error(name, attr);
    //console.error(this.attributes)
    this.parts.push(part);
  };

  this.setName = function (name) {
    this.name = name;
  };

  this.extracted = false;
};

//Constructor
/*private static class*/
function ProtoGeometrty(idx_, size) {
  // let /*Float32Array*/ vertices;
  // let /*Float32Array*/ normals;
  // let offset;
  // let minY = +Number.MAX_VALUE;
  // let maxY = -Number.MAX_VALUE;
  // let idx;

  //public ProtoGeometrty(int idx,int size) {
  this.idx = idx_;
  this.vertices = new Float32Array(size);
  this.normals = new Float32Array(size);
  this.offset = 0;
  //}
}

/*HashMap<Integer, TeethBufferGeomatry> */
function classify(
  /*MasterFile*/ masterFile,
  /*int*/ missingAndExtractedTeethNumber
) {
  let /*Uint32Array*/ _indices = masterFile.body.indices;
  let /*Float32Array*/ _vertices = masterFile.body.vertices;
  let /*Float32Array*/ _normals = masterFile.smoothNormals;
  let /*Float32Array*/ _shapeAttributes;
  if (masterFile.body.shapeAttributes) {
    _shapeAttributes = masterFile.body.shapeAttributes;
  } else if (masterFile.body.attrMaps) {
    _shapeAttributes = masterFile.body.attrMaps[0].attr;
  }

  // _shapeAttributes === undefined ?  console.log("-attributes", _shapeAttributes) : console.log('skip');

  /*HashMap<Integer, ProtoGeometrty>*/
  let protoGeometries = []; //new HashMap<Integer, ProtoGeometrty>();

  /*double*/
  let attr /*[]*/ = []; /*new double[3];*/
  let strangeFaces = 0;

  //consume a whole face, i.e. 3 indices
  for (let i = 0; i < _indices.length; i += 3) {
    for (let vIdx = 0; vIdx < 3; vIdx++) {
      let p = _indices[i + vIdx];
      let aidx = p * 4;

      //XXX new  ctm files do not specify the attributes for step 0
      attr[vIdx] = !_shapeAttributes
        ? EonShapeConstants.TOOTH_START
        : _shapeAttributes[aidx];
      if (
        attr[vIdx] < EonShapeConstants.TOOTH_START ||
        attr[vIdx] >= EonShapeConstants.ATTACHMENTS_START
      )
        attr[vIdx] = EonShapeConstants.TOOTH_START;
    }

    if (attr[0] !== attr[1] || attr[1] !== attr[2] || attr[0] !== attr[2]) {
      // console.log("skipping face " + attr[0] + " " + attr[1]  + " " + attr[2]);
      strangeFaces++;

      //NO MORE -- ASSIGN ALL STRANGE FACES TO firtst tooth !!!
      // attr[0] = attr[1] = attr[2] = EonShapeConstants.TOOTH_START;
      // console.log("strange faces")
      continue;
    }

    let attrInt = attr[0];
    // console.log("attrInt", attrInt);
    let /*ProtoGeometrty*/ protoGeometry = protoGeometries[attrInt];
    if (!protoGeometry) {
      protoGeometry = new ProtoGeometrty(attrInt, _indices.length * 3); //upper bound
      protoGeometries[attrInt] = protoGeometry;
    }

    for (let vIdx = 0; vIdx < 3; vIdx++) {
      let p = _indices[i + vIdx];
      let idx = p * 3;

      let x = _vertices[idx];
      let y = _vertices[idx + 1];
      let z = _vertices[idx + 2];

      let nx = _normals[idx];
      let ny = _normals[idx + 1];
      let nz = _normals[idx + 2];

      let offset = protoGeometry.offset;
      protoGeometry.vertices[offset] = x;
      protoGeometry.vertices[offset + 1] = y;
      protoGeometry.vertices[offset + 2] = z;

      protoGeometry.normals[offset] = nx;
      protoGeometry.normals[offset + 1] = ny;
      protoGeometry.normals[offset + 2] = nz;
      protoGeometry.offset += 3;

      protoGeometry.minY = protoGeometry.minY < y ? protoGeometry.minY : y;
      protoGeometry.maxY = protoGeometry.maxY > y ? protoGeometry.maxY : y;
    }
  }

  //GWT.log("skipped" + strangeFaces + " strange faces");
  if (strangeFaces > 0) {
    // console.log("skipped", strangeFaces, " strange faces")
  }

  /*HashMap<Integer, TeethBufferGeomatry>*/
  let geometries = []; /*new HashMap<Integer, TeethBufferGeomatry>();*/

  for (let /*Entry<Integer, ProtoGeometrty>*/ e in protoGeometries /*.entrySet()*/) {
    let entry = { key: e, value: protoGeometries[e] };

    //LLlog(e.getValue());
    /*TeethBufferGeomatry*/
    let geometry = new BufferGeometry(); //new TeethBufferGeomatry();

    let offset = entry.value.offset;
    //Window.alert("pg: " + e.getKey() + " elems: " + e.getValue().offset );
    let /*Float32Array*/ v = new Float32Array(offset);
    let /*Float32Array*/ n = new Float32Array(offset);

    let /*Float32Array*/ normals = entry.value.normals;
    let /*Float32Array*/ vertices = entry.value.vertices;
    for (let i = 0; i < offset; i++) {
      n[i] = normals[i];
      v[i] = vertices[i];
    }

    geometry.addAttribute("position", new BufferAttribute(v, 3));

    geometry.addAttribute("normal", new BufferAttribute(n, 3));

    geometries[entry.key] = geometry;
    entry.value.vertices = undefined;
    entry.value.normals = undefined;
  }

  // console.log("proto geometries", protoGeometries)

  // let extractedTeethNumber =
  //   missingAndExtractedTeethNumber + protoGeometries.length - 16;
  //
  // // if (extractedTeethNumber > 0) {
  // //   /*ArrayList<ProtoGeometrty>*/
  // //   let boxes = []; //new ArrayList<ProtoGeometrty>(protoGeometries.values());
  // //   // Collections.sort(boxes, new Comparator<ProtoGeometrty>() {
  // //
  // //   for (let i = 0; i < extractedTeethNumber; i++) {
  // //     let /*ProtoGeometrty*/ l = boxes[boxes.length - i - 1];
  // //
  // //     let /*TeethBufferGeomatry*/ bg = geometries[l.idx];
  // //     bg.setExtracted(true);
  // //   }
  // //
  // //   //if missing_teeth_new is null
  // //   //no extracted teeth are searched
  // //   //what follows is done per arc !! (in the viewer)
  // //   //if missing_teeth (mtN) + number of given teeth in the mesh (N) = 16
  // //   //   no extracted teeth are searched
  // //   //Ne = (mtN + N) - 16;
  // //   //compute the shortest
  // //   //compute the two with less triangles
  // //   //(sarebbe bello coincidessero)
  // //   //choose that for extracted
  // //   //try to number
  // //   //see if fits, i.e. the extracted goes to the right position
  // //   //FIXME
  // //   //FIXME
  // //   //FIXME
  // //   //teeth height computation
  // // }

  //protoGeometries.clear();

  return geometries;
}

/* EonTeethGeometry */
function segment(
  /*MasterFile*/ masterFile,
  /*int*/ missingAndExtractedTeethNumber
) {
  var /*EonTeethGeometry, an ArrayList*/ teeth = []; /*new EonTeethGeometry();*/

  var /*HashMap<Integer, TeethBufferGeomatry>*/ components = classify(
      masterFile,
      missingAndExtractedTeethNumber
    );

  // console.log("-components", components)
  for (
    let i = EonShapeConstants.TOOTH_START;
    i < EonShapeConstants.TOOTH_START + 45;
    i++
  ) {
    /*TeethBufferGeomatry*/
    var c = components[i];
    if (c) {
      // console.log("reading a component, ", i)
      c.teethNumber = i - EonShapeConstants.TOOTH_START;
      teeth.push(c);
    }
  }

  return teeth;
}

/*Mesh*/
var makeTeethMesh = function (/*EonTeethGeometry*/ eonTeethGeometry) {
  let /*Mesh*/ eonMesh = new Mesh();

  let num = 0;
  for (/*TeethBufferGeomatry*/ let index in eonTeethGeometry) {
    let a = eonTeethGeometry[index];

    //console.log("AA",a);

    let /*Material*/ _material;
    if (a.isExtracted()) {
      // MeshLambertMaterial mtl = new MeshLambertMaterial();
      // mtl.setColor(gumsColor);
      // mtl.setShading(Material.SHADING.SMOOTH);
      // _material = mtl;
      _material = { name: "EXTRACTED MATERIAL" };
    } else {
      // MeshPhongMaterial material = new MeshPhongMaterial();
      // material.setShading(Material.SHADING.SMOOTH);
      // material.setShininess(5);
      // _material = material;
      _material = { name: "TOOTH MATERIAL" };
    }

    let /*Mesh*/ m = new Mesh(a, _material);
    m.setName("" + a.teethNumber);
    eonMesh.add(m);
    //}
    num++;
  }

  // console.log("eonMesh",eonMesh)

  return eonMesh;
};

var /* MasterFile*/ __computeFromEmbeddedDelta = function (
    /*MasterFile*/ masterFile,
    /*int*/ deltaNum
  ) {
    let /*Float32Array*/ deltas =
        masterFile.body.attrMaps[deltaNum]
          .attr; /* masterFile.getAttribute(deltaNum);*/
    return __computeDelta(masterFile, deltas);
  };

var computeNormals = function (indices, vertices) {
  var smooth = new Float32Array(vertices.length),
    indx,
    indy,
    indz,
    nx,
    ny,
    nz,
    v1x,
    v1y,
    v1z,
    v2x,
    v2y,
    v2z,
    len,
    i,
    k;

  for (i = 0, k = indices.length; i < k; ) {
    indx = indices[i++] * 3;
    indy = indices[i++] * 3;
    indz = indices[i++] * 3;

    v1x = vertices[indy] - vertices[indx];
    v2x = vertices[indz] - vertices[indx];
    v1y = vertices[indy + 1] - vertices[indx + 1];
    v2y = vertices[indz + 1] - vertices[indx + 1];
    v1z = vertices[indy + 2] - vertices[indx + 2];
    v2z = vertices[indz + 2] - vertices[indx + 2];

    nx = v1y * v2z - v1z * v2y;
    ny = v1z * v2x - v1x * v2z;
    nz = v1x * v2y - v1y * v2x;

    len = Math.sqrt(nx * nx + ny * ny + nz * nz);
    if (len > 1e-10) {
      nx /= len;
      ny /= len;
      nz /= len;
    }

    smooth[indx] += nx;
    smooth[indx + 1] += ny;
    smooth[indx + 2] += nz;
    smooth[indy] += nx;
    smooth[indy + 1] += ny;
    smooth[indy + 2] += nz;
    smooth[indz] += nx;
    smooth[indz + 1] += ny;
    smooth[indz + 2] += nz;
  }

  for (i = 0, k = smooth.length; i < k; i += 3) {
    len = Math.sqrt(
      smooth[i] * smooth[i] +
        smooth[i + 1] * smooth[i + 1] +
        smooth[i + 2] * smooth[i + 2]
    );

    if (len > 1e-10) {
      smooth[i] /= len;
      smooth[i + 1] /= len;
      smooth[i + 2] /= len;
    }
  }

  return smooth;
};

/*private static native MasterFile*/
let __computeDelta = function (
  /*MasterFile*/ masterFile,
  /*Float32Array*/ deltas
) {
  var base_vertices = masterFile.body.vertices;
  var new_vertices = new Float32Array(base_vertices.length);

  var num_vertices = base_vertices.length / 3;
  for (var i = 0; i < num_vertices; i++) {
    var vn = i * 3;
    var dn = i * 4; //deltas are shifted by one cause attributes are 4Dimensional
    new_vertices[vn] = base_vertices[vn] + deltas[dn + 1];
    new_vertices[vn + 1] = base_vertices[vn + 1] + deltas[dn + 2];
    new_vertices[vn + 2] = base_vertices[vn + 2] + deltas[dn + 3];
  }

  var o = {
    header: masterFile.header,
    body: {
      attrMaps: masterFile.body.attrMaps,
      indices: masterFile.body.indices,
      vertices: new_vertices,
    },
  };

  o.smoothNormals = computeNormals(o.body.indices, o.body.vertices);

  return o;
};

var computeNthTeethGeometry = function (
  n,
  missingAndExtractedTeethNumber,
  theMasterFile
) {
  // console.log("computeNthTeethGeometry ---- n is ", tedn)
  let /*MasterFile*/ m = __computeFromEmbeddedDelta(theMasterFile, n);
  return segment(m, missingAndExtractedTeethNumber);
};

var createMeshes = function (
  masterFile,
  /*EonXHRLoader<EonTeethGeometry>*/ /* loader,*/ /*EonTeethGeometry*/ geometry,
  from,
  to,
  missingAndExtractedTeethNumber
) {
  // //XXX
  // //poi questi cast li facciamo sparire
  // final EonTeethLoader teethLoader = (EonTeethLoader)loader;

  /*ArrayList<Mesh>*/
  let meshes = []; //new ArrayList<Mesh>();

  //int nd = teethLoader.getNumDeltas();
  //this is what we find inside the file
  //but the number of attributer inside the file is unreliable
  //to deterine the number of deltas
  //being at least 1

  let nd = to - from - 1; // minus 1 because one is the master file, here we measure deltas

  // console.error("--->",nd);

  let /*Mesh*/ mb = makeTeethMesh(geometry);
  // console.error("mb", mb);

  meshes.push(mb);
  //progressBar.bindTeeth(from,mb);

  for (let i = 0; i < nd; i++) {
    let /*EonTeethGeometry*/
      eonGeometry = computeNthTeethGeometry(
        i,
        missingAndExtractedTeethNumber,
        masterFile
      );
    let /*Mesh*/ m = makeTeethMesh(eonGeometry);
    meshes.push(m);
    //progressBar.bindTeeth(from+i+1,m);
  }

  //bus.fireEvent(new LoadingEvent(statusEventTag, "", StatusType.END));

  return meshes;
};

let teethCallback = function (
  decompressed,
  t_range_start,
  t_range_end,
  missingAndExtractedTeethNumber,
  color_table,
  scene,
  isUpper
) {
  const p = new Promise((resolve, reject) => {
    // console.log("-decompressed", decompressed)

    let segmented = segment(decompressed, 0);

    // console.log("-segmented", segmented)

    let res = createMeshes(
      decompressed,
      segmented,
      t_range_start,
      t_range_end,
      missingAndExtractedTeethNumber
    );

    // console.log("-meshes", res);

    for (let i in res) {
      let x = Number(i);
      const group = new THREE.Group();
      group.name = isUpper ? "upper_teeth" : "lower_teeth";
      for (let p = 0; p < res[i].parts.length; p++) {
        var one = res[i].parts[p].geom.attributes;
        //console.log(res[0].parts[0].geom.attributes);

        var geometry = new THREE.BufferGeometry();
        var vertices = one.position.v;
        var normals = one.normal.v;

        geometry.name = res[i].parts[p].name;

        // itemSize = 3 because there are 3 values (components) per vertex
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(vertices, 3)
        );
        geometry.setAttribute("normal", new THREE.BufferAttribute(normals, 3));
        //var material = new THREE.MeshBasicMaterial( { color: 0xf0f0ff } );
        geometry = mergeVertices(geometry)
        geometry.computeVertexNormals()
        geometry.computeBoundingBox();
        let uniforms = {
          bbMin: { value: geometry.boundingBox.min },
          bbMax: { value: geometry.boundingBox.max },
          color1: { value: new THREE.Color("#ead5cf") },
          color2: { value: new THREE.Color("#ead5cf") },
          color3: { value: new THREE.Color("#ead5cf") },
          color4: { value: new THREE.Color("#ead5cf") },
        };
        let material = new THREE.MeshPhysicalMaterial({
          flatShading: false,
          side: THREE.DoubleSide,
          metalness: 0.1,
          roughness: 0.5,
          clearcoat: 0.1,
          emissive: '#8a8a8a',
          emissiveIntensity: 1,
          //clearcoatRoughness: 0.3,
          // envMap: texter,
          // combine: AddOperation,
          // reflectivity: 0.1,
          onBeforeCompile: shader => {
            shader.uniforms.bbMin = uniforms.bbMin;
            shader.uniforms.bbMax = uniforms.bbMax;
            shader.uniforms.color1 = isUpper ? uniforms.color1 : uniforms.color3;
            shader.uniforms.color2 = isUpper ? uniforms.color2 : uniforms.color4;
            shader.vertexShader = `
                          varying vec3 vPos;
                        ${shader.vertexShader}
                      `.replace(
                `#include <begin_vertex>`,
                `#include <begin_vertex>
                      vPos = transformed;
                      `
            );
            shader.fragmentShader = `
                        uniform vec3 bbMin;
                        uniform vec3 bbMax;
                        uniform vec3 color1;
                        uniform vec3 color2;
                        varying vec3 vPos;
                        ${shader.fragmentShader}
                      `.replace(
                `vec4 diffuseColor = vec4( diffuse, opacity );`,
                `
                        float f = clamp((vPos.y - bbMin.y) / (bbMax.y - bbMin.y), 0., 1.);
                        vec3 col = mix(color1, color2, f);
                        vec4 diffuseColor = vec4( col, opacity );`
            );
          },
        });
        var mesh = new THREE.Mesh(geometry, material);
        mesh.number = res[i].parts[p].name; // we keep the name both in the geometry and the mesh

        // console.log("adding tooth to group", p, "toothNumber", geometry)
        group.add(mesh);
      }

      // console.log("GROUP", group)

      scene.addTeeth(x + t_range_start, group);

      // geometry.dispose();
      // vertices = null;
      // normals = null;
    }
    resolve("teeth ok");
  });

  return p;
};

export default { parse: teethCallback };
