
// Driver Code
let arr = [1, 2, 4, 5, 6, 26, 27, 29];
let target = 28;



export const findClosestVal = (arr, tar) => {
  var closest = arr.reduce(function (prev, curr) {
    return Math.abs(curr - tar) < Math.abs(prev - tar) ? curr : prev;
  });
  return closest;
};
