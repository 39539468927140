import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import GenerateLabelsUpper from "./bounty-components/generate-labels_upper";
import GenerateLabelsLower from "./bounty-components/generate-labels-lower";
import PlayPauseAnimate from "./bounty-components/animate-steps";
import GenerateSteps from "./bounty-components/generate-steps";
import {
  resolvedData,
  howManyTsFiles,
  resolvePromise,
  generateStepsForMeshes,
  handlingconfigrasions,
  getParams,
} from "../TS-Helper";
import { useLottie } from "lottie-react";
import animationData from "../../lotties/rotate-phone.json";

import loader from "../TS-VIEWER/loader";
import { createScene, meshesForStep } from "../TS-VIEWER/Scene";
import viewer from "../TS-VIEWER/viewer";
import {
  updateMeshesAccordingToStepNumber,
  indicatorTracking,
} from "./bounty-functionality";
import ProgressBar from "./bounty-components/progress-bar";
import ViewerNotFound from "./bounty-components/ts-not-found";
import Nav from "./bounty-components/nav";
import { CloseW, Expand, Shrink, Warning } from "./svg";
import "../bounty-template/bounty-style/bounty.scss";
import "../bounty-template/bounty-style/bounty-mobile.scss";
import { themeConfig } from "../TS-Helper/themeConfigrasions";

import { Helmet } from "react-helmet";
import { mapMFGTeethIPR } from "../TS-Helper/ipr/mapMFGTeethIPR";
// import { iprDomeData } from "../TS-VIEWER/iprdata";
function BountyTemplate(props) {
  function isMobileBrowser() {
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      navigator.userAgent || navigator.vendor || window.opera
    );
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    style: {
      height: 65,
      width: 65,
    },
  };
  const { View } = useLottie(defaultOptions);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(isMobileBrowser());
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const {
    caseId,
    tsId,
    doctorId,
    showBiteRecord = false,
    position,
    modalViewer,
    isAttachment = true,
    isZoom = true,
    isAdmin,
    tsHeight = "100vh",
    isExpand = true,
    dental_notation,
    isDoctor = false,
  } = props;
  let params = getParams();

  const tsContaner = useRef(null);
  const renderCanvas = useRef(null);
  const currentWidthStepsRef = useRef(null);

  const stepsRef = useRef([]);
  const rangeRef = useRef(null);
  const [tsOptions, setTSOptions] = useState({
    //default options
    STEPS_PAUSE: 500,
    showUpperArc: true,
    showLowerArc: true,
    showUpper: false, // for maxilla
    showLower: false, // for mandible
    loading: true,
    isTSViewerFound: true,
    isAnimateStart: false,
    hasSuperImpose: true,
    isLoaded: false,
    isTsPrepared: false,
    isZoom: true,
    viewerId: null,
    isDarkMode: false,
    isAttachment: true, // for toggle attachments
    isSuperImpose: false,
    isIpr: true, // for toggle IPR
    isShowNumbers: false, // for toggle Teeth Numbering/Naming
    isM: true,
    themeColor: "#44e2d0",
    cursor: "grab",
    steps: [],
    isSplit: false,
    hideStepper: false,
    resetMesh: false,
    isGrid: false,
    dental_notation: dental_notation,
    iprData: {},
    points: {},
    isDoctor: isDoctor,
    manifestVersion: 1,
    isExpand: isExpand || false,
    overlays: true,
    files: [],
    isOcclus: false,
  });
  let handlingTeethPerStep = () => {
    const upperSteps = stepsOptions.upperSteps;
    const lowerSteps = stepsOptions.lowerSteps;
    let steps;
    if (stepsOptions.startTogether) {
      steps = Array.from({ length: meshesForStep.length }, (v, i) => ({
        u: i < upperSteps ? true : false,
        l: i < lowerSteps ? true : false,
      }));
    } else if (!stepsOptions.startTogether) {
      steps = Array.from({ length: meshesForStep.length }, (v, i) =>
        upperSteps > lowerSteps
          ? {
              u: i < upperSteps ? true : false,
              l: i < upperSteps - lowerSteps ? false : true,
            }
          : {
              u: i < lowerSteps - upperSteps ? false : true,
              l: i < lowerSteps ? true : false,
            }
      );
    }
    setTSOptions((prevTSOptions) => ({
      ...prevTSOptions,
      steps: steps,
    }));
  };

  const [stepsOptions, setStepsOptions] = useState(0);
  const [zoom, setZoom] = useState(100);

  const [hover, setHover] = useState(false);
  const [tooltipActive, setTooltipActive] = useState(false);
  const [expand, setExpand] = useState(false);
  const [tsWidth, setTsWidth] = useState(window.innerWidth);
  const [parentWidth, setParentWidth] = useState(0);
  const [viewerControls, setViewerControls] = useState("bounty_rotate");
  const [progress, setProgress] = useState(0);
  const [countProgress, setCountProgress] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [preveStep, setPreveStep] = useState(currentStep);
  const [rangeValue, setRangeValue] = useState(0);
  const [pinchY, setPinchY] = useState(0);

  const handle = useFullScreenHandle();

  const [indicatorWidth, setIndicatorWidth] = useState(
    indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
  );

  let handleCursor = (cursor) => {
    if (cursor === "grab") {
      return "bounty_cursor_grab";
    }
    if (cursor === "move") {
      return "bounty_cursor_move";
    }
    if (cursor === "zoomIn") {
      return "bounty_cursor_zoomIn";
    }
    if (cursor === "zoomOut") {
      return "bounty_cursor_zoomOut";
    }
  };

  let maxStepsNumber = meshesForStep.length - 1;
  function sendMessage() {
    window.addEventListener("message", function (event) {
      console.log(typeof event.data, event, "msg");
      console.log(!event.data);
      if (
        event.data.type === "webpackWarnings" ||
        event.data.type === "webpackClose" ||
        !event.data
      ) {
        return;
      }
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        files: event.data.files || [],
        iprData: event.data.iprData
          ? event.data.iprData?.useModule
            ? mapMFGTeethIPR(event.data.iprData)
            : event.data.iprData
          : {},
        isIpr:
          Object.keys(event.data.iprData ? event.data.iprData : {}).length === 0
            ? false
            : true,
        dental_notation: event.data.dental_notation || "universal",
      }));
    });
  }

  const handleZoomIn = () => {
    setZoom((prev) => {
      if (prev < 500) {
        viewer.dollyIn(1);
        return prev + 25;
      } else return prev;
    });
  };

  const handleZoomOut = () => {
    setZoom((prev) => {
      if (prev > 25) {
        viewer.dollyOut(1);
        return prev - 25;
      } else return prev;
    });
  };

  let handleZoom = () => {
    if (tsOptions.cursor === "zoomIn") {
      if (zoom < 500) {
        viewer.dollyIn(1);
        setZoom(zoom + 25);
      } else return;
    }
    if (tsOptions.cursor === "zoomOut") {
      if (zoom > 25) {
        viewer.dollyOut(1);
        setZoom(zoom - 25);
      } else return;
    }
  };
  const handleExpand = () => {
    setExpand(!expand);
    if (expand === true) handle.exit();
    else handle.enter();
  };
  const setIPRDataValues = (iprData) => {
    console.log(iprData, tsOptions.isIpr, "func");
    console.log(Object.keys(iprData).length, "llllllllllllllllll");
    setTSOptions((prevTSOptions) => ({
      ...prevTSOptions,
      iprData: iprData || {},
      isIpr: Object.keys(iprData).length === 0 ? false : true,
    }));
  };
  const setDentalNotation = (dentalNotation) => {
    setTSOptions((prevTSOptions) => ({
      ...prevTSOptions,
      dental_notation:
        dentalNotation || tsOptions.dental_notation || "universal",
    }));
  };
  const handleOverlays = () => {
    setTSOptions((prevTSOptions) => ({
      ...prevTSOptions,
      overlays: false,
    }));
  };
  const setPointsDataValues = (points) => {
    setTSOptions((prevTSOptions) => ({
      ...prevTSOptions,
      points: points || {},
    }));
  };
  useEffect(() => {
    console.log(tsOptions.iprData, "useEffect");
  }, [tsOptions.iprData]);
  let preparingTSViewer = useCallback(async () => {
    try {
      const {
        viewerId,
        isAccess,
        caseId,
        tsId,
        doctorId,
        isM,
        isAdmin,
        isDoctor,
        isPatient,
      } = await handlingconfigrasions(setTSOptions, props);
      if (params["isAdmin"]) {
        sendMessage();
      }
      if (
        isDoctor ||
        (tsOptions.isAdmin && tsOptions.files.length > 0) ||
        isPatient
      ) {
        const resolver = await resolvePromise(
          viewerId,
          isAccess,
          caseId,
          tsId,
          doctorId,
          tsOptions.files,
          isM,
          tsOptions.isAdmin,
          setIPRDataValues,
          setPointsDataValues,
          handleOverlays,
          setDentalNotation
        );
        let { data } = await resolvedData(resolver("manifest.json"));
        const lowerSteps = data["lowerStepsNumber"];
        const upperSteps = data["upperStepsNumber"];
        const startTogether = data["startTogether"];
        let passiveAligners = data["passiveAligners"];
        let upperOvercorrectionStepsNumber =
          data["upperOvercorrectionStepsNumber"];
        let lowerOvercorrectionStepsNumber =
          data["lowerOvercorrectionStepsNumber"];
        if (lowerSteps < upperSteps) {
          // override lowerOvercorrectionStepsNumber
          lowerOvercorrectionStepsNumber =
            data["lowerOvercorrectionStepsNumber"];
        }
        if (upperSteps < lowerSteps) {
          // override upperOvercorrectionStepsNumber
          upperOvercorrectionStepsNumber =
            data["upperOvercorrectionStepsNumber"];
        }
        setStepsOptions({
          lowerSteps,
          upperSteps,
          startTogether,
          passiveAligners,
          lowerOvercorrectionStepsNumber,
          upperOvercorrectionStepsNumber,
        });

        let howManyFiles = howManyTsFiles(data, true, tsOptions.hasSuperImpose);
        setCountProgress(howManyFiles);
        generateStepsForMeshes(lowerSteps, upperSteps);
        setTSOptions((prevTSOptions) => ({
          ...prevTSOptions,
          manifestVersion: data?.manifestVersion || 1,
        }));
        let scene = createScene(lowerSteps, upperSteps, startTogether);
        await loader(
          resolver,
          data,
          scene,
          setProgress,
          setCountProgress,
          setTSOptions,
          tsOptions.isAttachment,
          tsOptions.hasSuperImpose
        );
        updateMeshesAccordingToStepNumber(
          currentStep,
          tsOptions,
          stepsOptions.startTogether,
          isMobile
        );
        setTSOptions((prevTSOptions) => ({
          ...prevTSOptions,
          loading: false,
          isTsPrepared: true,
          isLoaded: true,
        }));
      }
    } catch (err) {
      //  Treatment plan not found
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        loading: false,
        isTSViewerFound: false,
        isTsPrepared: false,
        isLoaded: true,
      }));
      console.log(err, "Treatment plan not found");
    }
  }, [tsOptions.isAdmin, tsOptions.files]);

  const handleResizeContainer = () => {
    setTsWidth(window.innerWidth);
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  };
  useEffect(() => {
    setTSOptions((prevTSOptions) => ({
      ...prevTSOptions,
      isExpand,
    }));
  }, [isExpand]);
  useEffect(() => {
    window.addEventListener("resize", handleResizeContainer);
    window.addEventListener("resize", viewer.zoomValue);
    handleResizeContainer();
  }, [expand]);
  useEffect(() => {
    if (!handle.active) setExpand(false);
    else if (!handle.active) setExpand(true);
  }, [handle.active]);

  const evCache = [];
  let prevDiff = -1;
  useEffect(() => {
    if (!tsOptions.isLoaded && !tsOptions.isAdmin) {
      preparingTSViewer();
    } else {
      handlingTeethPerStep();
    }
  }, [tsOptions.isLoaded, tsOptions.isAdmin]);
  useEffect(() => {
    if (!tsOptions.isLoaded && tsOptions.isAdmin) {
      preparingTSViewer();
    } else {
      handlingTeethPerStep();
    }
  }, [tsOptions.isLoaded, tsOptions.isAdmin, tsOptions.files]);
  useEffect(() => {
    if (!tsOptions.overlays && tsOptions.isLoaded) {
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        loading: false,
        isTsPrepared: false,
        isLoaded: true,
      }));
      viewer.initializeAndRun(
        renderCanvas.current,
        {
          action: "bounty-template",
        },
        tsOptions,
        tsOptions.manifestVersion
      );
    }
  }, [tsOptions.overlays, tsOptions.isLoaded]);

  // useEffect(() => {
  //   //themeConfig(tsOptions.themeColor)
  //   setIndicatorWidth(
  //     indicatorTracking(preveStep, meshesForStep, currentWidthStepsRef)
  //   );
  // }, [
  //   preveStep,
  //   tsOptions.showUpperArc,
  //   tsOptions.showLowerArc,
  //   tsOptions.showUpper,
  //   tsOptions.showLower,
  //   tsOptions.isAttachment,
  //   tsOptions.isSuperImpose,
  //   tsOptions.isIpr,
  //   tsOptions.isShowNumbers,
  //   tsOptions.isAnimateStart,
  //   meshesForStep.length,
  //   isExpand,
  //   tsOptions.isExpand,
  // ]);
  useEffect(() => {
    if (meshesForStep.length && tsOptions.isLoaded) {
      updateMeshesAccordingToStepNumber(
        currentStep,
        tsOptions,
        stepsOptions.startTogether,
        isMobile
      );
    }
  }, [
    currentStep,
    tsOptions.isLoaded,
    tsOptions.showUpperArc,
    tsOptions.showLowerArc,
    tsOptions.showUpper,
    tsOptions.showLower,
    tsOptions.isAttachment,
    tsOptions.isSuperImpose,
    tsOptions.isIpr,
    tsOptions.isShowNumbers,
    tsOptions.isAnimateStart,
    tsOptions.cursor,
    tsOptions.isGrid,
    modalViewer,
    isExpand,
    tsOptions.isExpand,
    tsOptions.points,
    tsOptions.dental_notation,
  ]);
  // useEffect(() => {
  //   viewer.resetPosition();
  // }, [tsOptions.resetMesh]);
  useEffect(() => {
    viewer.handleGridSize(zoom);
    renderCanvas.current.addEventListener("contextmenu", (event) =>
      event.preventDefault()
    );
    window.addEventListener(
      "pointermove",
      function (e) {
        if (tsOptions.loading || tsOptions.isTsPrepared) return;
        const index = evCache.findIndex(
          (cachedEv) => cachedEv.pointerId === e.pointerId
        );
        evCache[index] = e;
        if (evCache.length === 2) {
          // Calculate the distance between the two pointers
          const curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);
          if (prevDiff > 0) {
            if (curDiff > prevDiff) {
              // The distance between the two pointers has increased
              handleZoomIn();
            }
            if (curDiff < prevDiff) {
              // The distance between the two pointers has decreased
              handleZoomOut();
            }
          }
          // Cache the distance for the next move event
          prevDiff = curDiff;
        } else if (evCache.length === 1) {
          viewer.onMouseMove(e);
        }
      },
      false
    );
    renderCanvas.current.addEventListener(
      "pointerdown",
      function (e) {
        e.preventDefault();
        if (tsOptions.loading || tsOptions.isTsPrepared) return;
        if (e.button === 2) {
          setTSOptions((prevTSOptions) => ({
            ...prevTSOptions,
            cursor: "move",
          }));
          setViewerControls("bounty_pan");
        }
        evCache.push(e);
        viewer.onMouseDown(e);
      },
      false
    );
    renderCanvas.current.addEventListener(
      "pointerup",
      function (e) {
        if (tsOptions.loading || tsOptions.isTsPrepared) return;
        if (e.button === 2) {
          setTSOptions((prevOptions) => {
            return { ...prevOptions, cursor: "grab" };
          });
          setViewerControls("bounty_rotate");
        }
        evCache.splice(0, evCache.length);
        if (evCache.length < 2) {
          prevDiff = -1;
        }
        viewer.onMouseUp(e);
      },
      false
    );
  }, [tsOptions.cursor, tsOptions.loading, tsOptions.isTsPrepared]);

  useEffect(() => {
    if (tsOptions.isLoaded && tsOptions.isTSViewerFound) {
      if (showBiteRecord) {
        viewer.views(position);
        setTSOptions((prevOptions) => {
          return {
            ...prevOptions,
            isShowNumbers: false,
            isSuperImpose: false,
            showLowerArc: true,
            showUpperArc: true,
            showUpper: false,
            showLower: false,
          };
        });
      } else {
        viewer.views("front");
      }
    }
  }, [tsOptions.isLoaded, showBiteRecord, position]);
  useEffect(() => {
    return () => {
      viewer.resetZoom();
      meshesForStep.filter(() => {
        return meshesForStep.splice(0);
      });
    };
  }, []);
  return (
    <FullScreen handle={handle}>
      <div
        id="bounty_template"
        //className={expand ? 'bounty_template_full_screen': '' }
        style={{ height: "100vh" }}
        ref={tsContaner}
        onWheel={(event) => {
          // zoom in / out on wheel
          if (
            tsOptions.loading ||
            tsOptions.isTsPrepared ||
            !tsOptions.isTSViewerFound
          ) {
            return;
          } else {
            if (event.deltaY > 0) {
              handleZoomOut();
            }
            if (event.deltaY < 0) {
              handleZoomIn();
            }
          }
        }}
      >
        <Helmet>
          {/* disable zoom on mobile web page */}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
            user-scalable=0"
          />
          <title>Plan Viewer</title>
        </Helmet>
        <div
          id="viewer-window"
          className={`${
            isMobile
              ? "bounty_thewiewerwindow_mobile"
              : "bounty_thewiewerwindow"
          }`}
          style={{ height: expand ? "100vh" : tsHeight }}
        >
          {/* <!-- Trigger/Open The Modal --> */}
          {tsOptions.isSplit && <div className="bounty_screen_divider"></div>}
          {tsOptions.isGrid && (
            <div style={{ top: "85px" }} className={`bounty_grid_indicate`}>
              Grid not true to scale
            </div>
          )}
          <Nav
            isMobile={isMobile}
            viewerControls={viewerControls}
            setViewerControls={setViewerControls}
            isDoctor={tsOptions.isDoctor}
            isAdmin={tsOptions.isAdmin}
            handle={handle}
            isExpand={isExpand}
            expand={expand}
            setExpand={setExpand}
            primary={tsOptions.themeColor}
            zoom={zoom}
            tsOptions={tsOptions}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            setTSOptions={setTSOptions}
            width={tsWidth}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            preveStep={preveStep}
            setPreveStep={setPreveStep}
            setZoom={setZoom}
          />
          <div
            style={
              tsOptions.loading || tsOptions.isTsPrepared
                ? { zIndex: "-2", display: "none" }
                : { zIndex: "1" }
            }
            className={
              isMobile ? "bounty_rotate_phone_mobile" : "bounty_rotate_phone"
            }
          >
            {View}
            <div>
              Rotate your phone<span> to get the full experience</span>
            </div>
          </div>
          {isExpand && isMobile && (
            <span
              onClick={() => handleExpand()}
              className={"bounty_nav_icon_full_screen_mobile"}
            >
              {!expand ? <Expand /> : <Shrink />}
            </span>
          )}
          <canvas
            className={`${
              isMobile ? "bounty_rendercanvas_mobile" : "bounty_rendercanvas"
            } ${handleCursor(tsOptions.cursor)} `}
            style={
              !tsOptions.isGrid
                ? {
                    background: `#464646 center / cover no-repeat url(${
                      process.env.REACT_APP_IMAGE_URL + "/bg.svg"
                    })`,
                  }
                : { background: `#464646 center / cover no-repeat url("")` }
            }
            ref={renderCanvas}
          ></canvas>
          {/* Progress bar  */}
          <ProgressBar
            loading={tsOptions.loading}
            progress={progress}
            countProgress={countProgress}
            isTsPrepared={tsOptions.isTsPrepared}
            frontView={viewer.views}
          />
          {/* Not found viewer  */}
          <ViewerNotFound isTSViewerFound={tsOptions.isTSViewerFound} />
          {/* <!-- Steps container --> */}
          {/* {Object.keys(tsOptions.iprData).length === 0 && (
            <div
              className="bounty_ipr_warning"
              style={{ display: !open ? "none" : "flex" }}
            >
              <Warning />
              IPR information might be missing due to technical issues. Please
              refer to the Treatment Plan.
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setOpen(false)}
              >
                <CloseW />
              </span>
            </div>
          )} */}
          {
            <div
              className={
                isMobile
                  ? "bounty_steps_container_mobile"
                  : "bounty_steps_container"
              }
              style={
                tsOptions.loading ||
                tsOptions.isTsPrepared ||
                !tsOptions.isTSViewerFound
                  ? { zIndex: "-2", display: "none" }
                  : tsOptions.isSplit
                  ? { height: "0px" }
                  : { zIndex: "2" }
              }
            >
              {/* <!-- Play/Pause animate --> */}
              <PlayPauseAnimate
                isMobile={isMobile}
                maxStepsNumber={maxStepsNumber}
                width={tsWidth}
                isDarkMode={tsOptions.isDarkMode}
                tsOptions={tsOptions}
                setTSOptions={setTSOptions}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                meshesForStep={meshesForStep}
                preveStep={preveStep}
                setPreveStep={setPreveStep}
                stepsRef={stepsRef}
              />
              <div className="bounty_steps" ref={rangeRef}>
                <div className="bounty_total_steps">
                  {`${currentStep}/${maxStepsNumber}`}
                </div>
                <div
                  className={"bounty_steps_content"}
                  ref={currentWidthStepsRef}
                >
                  {/* <!-- Generate upper steps --> */}
                  <GenerateSteps
                    isMobile={isMobile}
                    tooltipActive={tooltipActive}
                    setTooltipActive={setTooltipActive}
                    hover={hover}
                    steps={tsOptions.steps}
                    isTsPrepared={tsOptions.isTsPrepared}
                    isLoaded={tsOptions.isLoaded}
                    isTSViewerFound={tsOptions.isTSViewerFound}
                    meshesForStep={meshesForStep}
                    setCurrentStep={setCurrentStep}
                    loading={tsOptions.loading}
                    lowerSteps={stepsOptions.lowerSteps}
                    upperSteps={stepsOptions.upperSteps}
                    allStepsForUpper={stepsOptions.upperSteps} // all upper steps
                    allStepsForLower={stepsOptions.lowerSteps} // all upper steps
                    startTogether={stepsOptions.startTogether}
                    passiveAligners={stepsOptions.passiveAligners}
                    upperOverCorrectionStepsNumber={
                      stepsOptions.upperOvercorrectionStepsNumber // overcorrection for upper steps
                    }
                    lowerOverCorrectionStepsNumber={
                      stepsOptions.lowerOvercorrectionStepsNumber // overcorrection for upper steps
                    }
                    currentStep={currentStep}
                    setHover={setHover}
                    stepsRef={stepsRef}
                    preveStep={preveStep}
                    setPreveStep={setPreveStep}
                    isAnimateStart={tsOptions.isAnimateStart}
                    setRangeValue={setRangeValue}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </FullScreen>
  );
}

export default BountyTemplate;
