import * as THREE from "three";

import { TeethNumbering, TeethNumberingData } from "./teethParser";
import { MeshLine, MeshLineMaterial } from "meshline";
import { getToothNumber } from "../TS-Helper/teethNumbering";
import _ from "lodash";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import {DecalGeometry} from "three/examples/jsm/geometries/DecalGeometry"
import { getParams } from "../TS-Helper";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import poppins from "../../static/poppins_regular.json";
import { findClosestVal } from "./closest";

let controls;
let manifestVersion;
let INTERSECTED;
let renderer;
let cameraZoom = 9.5;
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();
const labelRenderer = new CSS2DRenderer();
const labelDiv = document.createElement("div");
const tooltip = new CSS2DObject(labelDiv);

let zoomValue = function () {
  if (window.innerWidth > 1900) {
    if (cameraZoom !== 12) return;
    else cameraZoom = 12;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
    camera2.zoom = cameraZoom;
    camera2.updateProjectionMatrix();
  } else {
    if (cameraZoom !== 9.5) return;
    else cameraZoom = 9.5;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
    camera2.zoom = cameraZoom;
    camera2.updateProjectionMatrix();
  }
};
let toothNumbers = [];
const group = new THREE.Group();
const group2 = new THREE.Group();

const camera = new THREE.OrthographicCamera(
  window.innerWidth / -2,
  window.innerWidth / 2,
  window.innerHeight / 2,
  window.innerHeight / -2,
  1,
  1000
);
const camera2 = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.01, 1000);
let scene = new THREE.Scene();
let scene2 = new THREE.Scene();

//lights
const color = 0xffffff;
const intensity = 1;
const light = new THREE.DirectionalLight(color, intensity);
light.position.set(0.25, 3, -2.25);
const light2 = new THREE.PointLight(color, 1);
light2.power = 25;
const light3 = new THREE.DirectionalLight(color, intensity);
light3.position.set(0.25, 3, -2.25);
const light4 = new THREE.PointLight(color, 1);
light4.power = 25;

//sets camera to front view;
camera.position.z = 60;
camera.position.y = 0;
camera.position.x = 0;
camera.zoom = cameraZoom;
camera2.position.z = 60;
camera2.position.y = 0;
camera2.position.x = 0;
camera2.zoom = cameraZoom;
let center = { x: 0, y: 0, z: -50 };
export const views = (action, isSplit) => {
  switch (action) {
    case "front":
      group.rotation.x = 0;
      group.rotation.y = 0;
      group.rotation.z = 0;
      group.position.set(-center.x, -center.y, -Math.abs(center.z - 50));
      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
        group2.position.set(-center.x, -center.y, -Math.abs(center.z - 50));
      }
      break;
    case "left":
      group.rotation.x = 0;
      group.rotation.y = -1.5;
      group.rotation.z = 0;

      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = -1.5;
        group2.rotation.z = 0;
      }
      break;
    case "right":
      group.rotation.x = 0;
      group.rotation.y = 1.5;
      group.rotation.z = 0;

      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = 1.5;
        group2.rotation.z = 0;
      }
      break;
    case "upper":
      group.rotation.x = -1.5;
      group.rotation.y = 0;
      group.rotation.z = 0;

      if (isSplit) {
        group2.rotation.x = -1.5;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
      }
      break;
    case "lower":
      group.rotation.x = 1.5;
      group.rotation.y = 0;
      group.rotation.z = 0;

      if (isSplit) {
        group2.rotation.x = 1.5;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
      }
      break;
    default:
      group.rotation.x = 0;
      group.rotation.y = 0;
      group.rotation.z = 0;
      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
      }
  }
};
let resetPosition = () => {
  group.position.set(-center.x, -center.y, -Math.abs(center.z - 50));
  group2.position.set(-center.x, -center.y, -Math.abs(center.z - 50));
  group.rotation.x = 0;
  group.rotation.y = 0;
  group2.rotation.x = 0;
  group2.rotation.y = 0;
};
function dollyIn(dollyScale) {
  if (camera.type === "OrthographicCamera") {
    cameraZoom = cameraZoom + dollyScale;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  } else {
    console.warn(
      "WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled."
    );
  }
}

function dollyOut(dollyScale) {
  if (camera.type === "OrthographicCamera") {
    cameraZoom = cameraZoom - dollyScale;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  } else {
    console.warn(
      "WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled."
    );
  }
}

function resetZoom() {
  if (window.innerWidth > 1900) {
    cameraZoom = 12;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
    camera2.zoom = cameraZoom;
    camera2.updateProjectionMatrix();
  } else {
    cameraZoom = 9.5;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
    camera2.zoom = cameraZoom;
    camera2.updateProjectionMatrix();
  }
}

//a data structure we use to instruct 'render' function to eventually update
//scene
const sceneInfo = {
  dirty: false, //means cleanup and load following data
  activeMeshes: [], //the meshes to be loaded
  currentMeshes: [], //thew meshes to be removed
  currentLastMeshes: [], //thew meshes to be removed
  firstStepMeshes: [],
  superImposeDirty: false,
  removeFirstSteps: false,
  isIpr: false,
  isShowNumbers: false,
  superImposeActive: [],
  step: 0,
  lastStep: 0,
  beforeAndAfterDirty: false,
  beforeAndAfterActive: [],
  isLast: false,
  isBeforeAndAfter: false,
  cursor: "grab",
  isGrid: false,
  dental_notation: "universal",
  IPRdata: {},
  points: {},
  isExpand: false,
  startTogether: true,
  firstTime: true,
  isMobile: false
};

//set the meshes to display
let setMeshes = function (
  meshes,
  isSuperImpose,
  isIpr,
  isShowNumbers,
  meshesForFirstStep,
  n,
  lastStepMeshes,
  isBeforeAndAfter,
  cursor,
  isGrid,
  dental_notation,
  IPRdata,
  points,
  isExpand,
  lastStep,
  startTogether,
  isMobile
) {
  let params = getParams();
  sceneInfo.activeMeshes = meshes;
  sceneInfo.dirty = true;
  sceneInfo.superImposeActive = meshesForFirstStep;
  sceneInfo.isIpr = isIpr;
  sceneInfo.isShowNumbers = isShowNumbers;
  sceneInfo.superImposeDirty = isSuperImpose ? true : false;
  sceneInfo.removeFirstSteps = isSuperImpose ? false : true;
  sceneInfo.step = n;
  sceneInfo.beforeAndAfterDirty = isBeforeAndAfter ? true : false;
  sceneInfo.beforeAndAfterActive = lastStepMeshes;
  sceneInfo.isSplit = isBeforeAndAfter;
  sceneInfo.cursor = cursor;
  sceneInfo.dental_notation = dental_notation;
  sceneInfo.isGrid = isGrid;
  sceneInfo.IPRdata = IPRdata || {};
  sceneInfo.points = points || {};
  sceneInfo.isExpand = isExpand || false;
  sceneInfo.lastStep = lastStep;
  sceneInfo.startTogether = startTogether;
  sceneInfo.isMobile = isMobile
};

let changeSceneBackgroundColor = (canvas, isDarkMode) => {
  // const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
  // if (isDarkMode) {
  //   renderer.setClearColor("#1c1c1c", 1);
  // } else {
  //   renderer.setClearColor("#eeedeb", 1);
  // }
};
/* --------------------------------------------------------------------------------*/
/* get Center Point*/
/* --------------------------------------------------------------------------------*/
function getCenterPoint(mesh) {
  let geometry =
    mesh instanceof THREE.Mesh || mesh.isMesh ? mesh.geometry : mesh;
  geometry.computeBoundingSphere();
  const middle = geometry.boundingSphere.center;
  return middle;
}

/* --------------------------------------------------------------------------------*/
/* make Label Canvas*/
/* --------------------------------------------------------------------------------*/
function makeLabelCanvas(
  baseWidth,
  size,
  text,
  textColor,
  bgColor,
  borderColor,
  lablePos
) {
  text = " " + text + " ";
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = 500;
  canvas.height = 350;
  const font = `bold 175px 'Arial'`;
  ctx.font = font;
  ctx.fillStyle = bgColor;
  ctx.fillRect(10, 10, canvas.width - 20, canvas.height - 20);
  ctx.fillStyle = textColor;
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillText(text, canvas.width / 2, canvas.height / 2);

  return ctx.canvas;
}
/* --------------------------------------------------------------------------------*/
/* make Sprite Label*/
/* --------------------------------------------------------------------------------*/
function makeSpriteLabel(
  position,
  labelWidth,
  size,
  text,
  colors,
  anchorOnTop,
  lablePos,
  isLower
) {
  let canvas = "";

  canvas = makeLabelCanvas(
    labelWidth,
    size,
    text,
    colors.textColor,
    colors.bgColor,
    colors.borderColor,
    lablePos,
    anchorOnTop
  );

  const texture = new THREE.CanvasTexture(canvas);
  // because our canvas is likely not a power of 2
  // in both dimensions set the filtering appropriately.
  texture.minFilter = THREE.LinearFilter;
  texture.wrapS = THREE.ClampToEdgeWrapping;
  texture.wrapT = THREE.ClampToEdgeWrapping;

  const labelMaterial = new THREE.SpriteMaterial({
    map: texture,
    // side: THREE.DoubleSide,
    transparent: true,
  });

  const root = new THREE.Object3D();
  root.position.copy(position);

  // const labelGeometry = new THREE.PlaneBufferGeometry(1, 1);

  const label = new THREE.Sprite(labelMaterial);
  root.add(label);
  //label.position.y = anchorOnTop ? (-bodyHeight * 4) / 5 : (bodyHeight * 4) / 5;
  // label.position.z = bodyRadiusTop * 1.01;

  // if units are meters then 0.01 here makes size
  // of the label into centimeters.
  const labelBaseScale = 0.01;
  label.scale.x = canvas.width * labelBaseScale;
  label.scale.y = canvas.height * labelBaseScale;

  scene.add(root);
  return root;
}
/* --------------------------------------------------------------------------------*/
/* place IPR Labels Between Teeth */
/* --------------------------------------------------------------------------------*/
const findIfNextIsMissing = (teeth, tooth) => {
  let missing = _.find(teeth, (o) => {
    return o.number === (Number(tooth) - 1).toString();
  });
  return Object.keys(missing).length > 0;
};
const findIfMissingAndGetIndex = (toothNumber) => {
  let tooth =
    toothNumbers.indexOf(Number(toothNumber - 1)) === -1
      ? toothNumbers.indexOf(Number(toothNumber))
      : toothNumbers.indexOf(Number(toothNumber - 1));
  return toothNumbers[tooth];
};
function placeIPRLabelsBetweenTeeth(teeth, step, width) {
  const clone = (obj) => Object.assign({}, obj);
  const renameKey = (object, key, newKey) => {
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];
    delete clonedObj[key];
    clonedObj[newKey] = targetKey;

    return clonedObj;
  };
  let labels = [];
  if (Object.keys(sceneInfo.IPRdata).length > 0) {
    let lowerMissing = [];
    let upperMissing = [];
    teeth.forEach((t, idx) => {
      const toothNumber = t.number; //a bit misleading, the filed name contains the tooth number :|
      const numberingData = TeethNumbering.byNumber(toothNumber);
      let toothIprData;
      let isLower;
      let difference;
      let newToothNumber = 0;
      if (numberingData.name.startsWith("lower")) {
        isLower = true;
        lowerMissing = _.differenceBy(
          TeethNumberingData.filter((x) => x.number > 16),
          teeth,
          "number"
        );
        toothIprData = sceneInfo.IPRdata.lower_teeth[numberingData.name];
        t["isLower"] = isLower;
      } else {
        isLower = false;
        upperMissing = _.differenceBy(
          TeethNumberingData.filter((x) => x.number <= 16),
          teeth,
          "number"
        );
        toothIprData = sceneInfo.IPRdata.upper_teeth[numberingData.name];
        t["isLower"] = isLower;
      }
      let yx =
        _.find(lowerMissing, (o) => {
          return o.number === (Number(toothNumber) - 1).toString();
        }) || {};
      
      if (toothIprData && toothIprData.use_ipr === true) {
   

        // console.log(toothNumber, findClosest(toothNumbers.sort(), Number(toothNumber)));
        let direction =
          sceneInfo.points?.[isLower ? "lower" : "upper"]?.[step]?.ipr[
            isLower
              ? findClosestVal(toothNumbers, Number(toothNumber) - 1)
              : toothNumber
          ]?.direction;
        let point =
          sceneInfo.points?.[isLower ? "lower" : "upper"]?.[step]?.ipr[
            isLower
              ? findClosestVal(toothNumbers, Number(toothNumber) - 1)
              : toothNumber
          ]?.point;
        if (!direction && !point) return;
        const ipr_steps_amounts = toothIprData.ipr_steps_amounts;
        const at_current_step = ipr_steps_amounts.find(
          (e) => Number(e.step) === step
        );
        const tc = new THREE.Vector3(point?.[0], point?.[1], point?.[2]);
        let amount = 20;
        let angles = isLower ? -12 : 12;
        const labelpos = new THREE.Vector3(
          point?.[0] + amount * direction?.[0],
          point?.[1] + amount * direction?.[1] + angles,
          point?.[2] + amount * direction?.[2]
        );
        if (ipr_steps_amounts[0]?.amount) {
          const amount =
            step === 0
              ? ipr_steps_amounts[0]?.amount
              : at_current_step?.amount || ipr_steps_amounts[0]?.amount;

          const color =
            step === 0
              ? "white"
              : at_current_step
              ? "rgba(203, 226, 255, 1)"
              : "white";

          const fontColor =
            step === 0
              ? "rgba(46, 46, 47, 0.8)"
              : at_current_step
              ? "rgba(0, 112, 255, 1)"
              : "rgba(46, 46, 47, 0.8)";
          const label = makeSpriteLabel(
            labelpos,
            700,
            500,
            /*numberingData.displayName*/ amount,
            {
              textColor: fontColor,
              bgColor: color,
              borderColor: color,
            },
            isLower,
            "ipr"
          );

          /********************** get full text *********************** */
          const IPRStepsAmounts = [];
          for (var x = 0; x < ipr_steps_amounts.length; x++) {
            const string = `IPR ${ipr_steps_amounts[x]?.amount} mm at Step ${ipr_steps_amounts[x]?.step}`;
            IPRStepsAmounts.push(string);
          }
          let text = IPRStepsAmounts.join(`\n`);

          /************************************************************ */

          label["steps"] = text;
          label["isLower"] = isLower;
          label.name = "ipr";
          const line = createLine(tc, labelpos, "white");
          labels.push(label);
          labels.push(line);
        } else {
          // console.log("at step ", step, " NOT found ipr");
        }
      }
    });
  }
  return labels;
}

/* --------------------------------------------------------------------------------*/
/* place Labels OnTeeth */
/* --------------------------------------------------------------------------------*/
function placeLabelsOnTeeth(teeh, step) {
  //centers
  let labels = [];
  teeh.forEach((t, idx) => {
    const toothNumber = t.number; //a bit misleading, the filed name contains the tooth number :|
    const numberingData = TeethNumbering.byNumber(toothNumber);
    let isLower;
    let upper = 0;
    let lower = 0;
    let difference;
    let isUpperBigger;
    if (numberingData.name.startsWith("lower")) {
      isLower = true;
      if (step > Object.keys(sceneInfo.points?.lower).length - 1) {
        lower = Object.keys(sceneInfo.points?.lower).length - 1;
      } else lower = step;
    } else {
      isLower = false;
      if (step > Object.keys(sceneInfo.points?.upper).length - 1) {
        upper = Object.keys(sceneInfo.points?.upper).length - 1;
      } else {
        upper = step;
      }
    }
    if (!sceneInfo.startTogether) {
      if (
        Object.keys(sceneInfo.points.upper).length ===
        Object.keys(sceneInfo.points.lower).length
      ) {
        return;
      }
      if (
        Object.keys(sceneInfo.points.upper).length >
        Object.keys(sceneInfo.points.lower).length
      ) {
        difference =
          Object.keys(sceneInfo.points.upper).length -
          Object.keys(sceneInfo.points.lower).length;
        isUpperBigger = true;
      } else {
        difference =
          Object.keys(sceneInfo.points.lower).length -
          Object.keys(sceneInfo.points.upper).length;
        isUpperBigger = false;
      }
    }
    if (sceneInfo.points?.[isLower ? "lower" : "upper"]) {
      let direction =
        sceneInfo.points?.[isLower ? "lower" : "upper"]?.[
          step === 0
            ? 0
            : !sceneInfo.startTogether
            ? isUpperBigger
              ? isLower
                ? lower + difference
                : upper
              : isLower
              ? lower
              : upper + difference
            : isLower
            ? lower
            : upper
        ]?.labels[toothNumber]?.direction;
      let point =
        sceneInfo.points?.[isLower ? "lower" : "upper"]?.[
          step === 0
            ? 0
            : !sceneInfo.startTogether
            ? isUpperBigger
              ? isLower
                ? lower + difference
                : upper
              : isLower
              ? lower
              : upper + difference
            : isLower
            ? lower
            : upper
        ]?.labels[toothNumber]?.point;
      var font = new FontLoader().parse(poppins);
      let text = getToothNumber(toothNumber, sceneInfo.dental_notation).toString();
      const tGeo = new TextGeometry(text, {
        font: font,
        size: 2,
        height: 0.05,
      });
      const textMesh = new THREE.Mesh(
        tGeo,
        new THREE.MeshPhongMaterial({ color: "black" })
      );
      // const geometry =  new DecalGeometry( t.clone(), new THREE.Vector3(
      //   point?.[0],
      //   point?.[1],
      //   point?.[2]
      // ), new THREE.Euler( 0, 1, 1.57, 'XYZ' ), new THREE.Vector3(1,1,1) );
      // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
      // const mesh = new THREE.Mesh( geometry, material );
      // group.add( mesh );
      const label_distance = 0.01;
      const labelpos = new THREE.Vector3(
        point?.[0] + direction?.[0] * label_distance,
        point?.[1] + direction?.[1] * label_distance,
        point?.[2] + direction?.[2] * label_distance
      );
      tGeo.computeBoundingBox();
      tGeo.center();
      textMesh.geometry.dispose();
      textMesh.geometry = tGeo;
      textMesh.position.set(labelpos.x, labelpos.y, labelpos.z + 1);
      let y_rotation = Math.atan2(direction?.[0], direction?.[2]);

      textMesh.rotation.set(0, y_rotation, 0);

      textMesh.name = "teeth_num";
      labels.push(textMesh);
    }
  });
  return labels;
}
/* --------------------------------------------------------------------------------*/
/* create Line */
/* --------------------------------------------------------------------------------*/
let createLine = function (p0, p1, color) {
  const samples = [p0, p1];
  // const material = new THREE.LineBasicMaterial( { color: 0xffffff, /*dashSize: 1, gapSize: 0.5,*/ linewidth: 3} );
  // const line = new THREE.Line( geometry, material );
  // line.computeLineDistances();

  const geometry = new THREE.BufferGeometry().setFromPoints(samples);
  const line = new MeshLine();
  line.setGeometry(geometry);
  const material = new MeshLineMaterial({
    useMap: false, //tells the material to use map (0 - solid color, 1 use texture)
    color: color || "white",
    opacity: 0.38,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight), //THREE.Vector2 specifying the canvas size (REQUIRED)
    sizeAttenuation: false, // makes the line width constant regardless distance (1 unit is 1px on screen) (0 - attenuate, 1 - don't attenuate)
    transparent: true,
    lineWidth: 5,
    depthTest: true,
    alphaTest: 0, //cutoff value from 0 to 1
  });

  /* If you're rendering transparent lines or using a texture with alpha map, you should set
  depthTest to false, transparent to true and blending to an appropriate blending mode, or use alphaTest.*/

  const mesh = new THREE.Mesh(line.geometry, material);
  return mesh;
};
let helperGrid = new THREE.GridHelper(500, 10, "#6C6C6C", "#6C6C6C");
helperGrid.rotation.x = 1.5;
helperGrid.position.z = -2;

let helperGrid2 = new THREE.GridHelper(500, 100);
helperGrid2.rotation.x = 1.5;
helperGrid2.position.z = -5;
let helperGrid3 = new THREE.GridHelper(500, 10, "#6C6C6C", "#6C6C6C");
helperGrid3.rotation.x = 1.5;
helperGrid3.position.z = -2;

let helperGrid4 = new THREE.GridHelper(500, 100);
helperGrid4.rotation.x = 1.5;
helperGrid4.position.z = -5;
helperGrid.visible = false;
helperGrid2.visible = false;
helperGrid3.visible = false;
helperGrid4.visible = false;

scene2.add(helperGrid3);
scene2.add(helperGrid4);
scene.add(helperGrid);
scene.add(helperGrid2);

let handleGridSize = (zoom) => {
  if (zoom <= 75) {
    helperGrid.scale.x = 1.5;
    helperGrid.scale.y = 1.5;
    helperGrid.scale.z = 1.5;

    helperGrid2.scale.x = 1.5;
    helperGrid2.scale.y = 1.5;
    helperGrid2.scale.z = 1.5;
    helperGrid3.scale.x = 1.5;
    helperGrid3.scale.y = 1.5;
    helperGrid3.scale.z = 1.5;

    helperGrid4.scale.x = 1.5;
    helperGrid4.scale.y = 1.5;
    helperGrid4.scale.z = 1.5;
  } else if (zoom > 75 && zoom <= 250) {
    helperGrid.scale.x = 1;
    helperGrid.scale.y = 1;
    helperGrid.scale.z = 1;

    helperGrid2.scale.x = 1;
    helperGrid2.scale.y = 1;
    helperGrid2.scale.z = 1;
    helperGrid3.scale.x = 1;
    helperGrid3.scale.y = 1;
    helperGrid3.scale.z = 1;

    helperGrid4.scale.x = 1;
    helperGrid4.scale.y = 1;
    helperGrid4.scale.z = 1;
  } else if (zoom > 250) {
    helperGrid.scale.x = 0.5;
    helperGrid.scale.y = 0.5;
    helperGrid.scale.z = 0.5;

    helperGrid2.scale.x = 0.5;
    helperGrid2.scale.y = 0.5;
    helperGrid2.scale.z = 0.5;
    helperGrid3.scale.x = 0.5;
    helperGrid3.scale.y = 0.5;
    helperGrid3.scale.z = 0.5;

    helperGrid4.scale.x = 0.5;
    helperGrid4.scale.y = 0.5;
    helperGrid4.scale.z = 0.5;
  }
};

/* --------------------------------------------------------------------------------*/
/* show ipr label on hover  */
/* --------------------------------------------------------------------------------*/
const showLabelOnHover = (intersects, ray) => {
  if (intersects.length > 0) {
    const filtered = intersects.filter(
      (obj) => obj.object.parent.name === "ipr"
      //  &&
      // (obj.object.parent.isLower
      //   ? obj.point.y >= obj.object.parent.position.y
      //   : obj.point.y <= obj.object.parent.position.y)
    );

    for (let i = 0; i < filtered.length; i++) {
      if (INTERSECTED !== filtered[i].object) {
        // clear intersetes if found
        clearIntersectes();
        INTERSECTED = filtered[0].object;
        const point = filtered[0].point;
        const isLower = INTERSECTED.parent.isLower;
        const steps = INTERSECTED.parent.steps;
        const labelpos = new THREE.Vector3(point.x, point.y, point.z);
        // defined classnames and div content
        renderer.domElement.classList.add("hovered");
        labelDiv.className = isLower
          ? "bounty-lower-tooltip-sprite"
          : "bounty-tooltip-sprite";
        tooltip.visible = true;
        labelDiv.textContent = steps;
        // Move label over hovered element
        // tooltip.position.set(
        //   labelpos.x,
        //   labelpos.y + (isLower ? 0 : 6),
        //   labelpos.z
        // );
        //add label to the scene
        INTERSECTED.parent.add(tooltip);
      }
    }
  } else {
    clearIntersectes();
  }
};
/* --------------------------------------------------------------------------------*/
/* on Pointer Move */
/* --------------------------------------------------------------------------------*/
const onPointerMove = (event) => {
  // calculate pointer position in normalized device coordinates
  // (-1 to +1) for both components
  clearIntersectes();
  const width = sceneInfo.isSplit ? window.innerWidth / 2 : window.innerWidth;
  const height = sceneInfo.isExpand ? window.innerHeight : window.innerHeight;
  pointer.x = (event.clientX / width) * 2 - 1;
  pointer.y = -(event.clientY / height) * 2 + 1;
  raycaster.setFromCamera(pointer, camera);
  // calculate objects intersecting the picking ray
  const intersects = raycaster.intersectObjects(group.children);

  // show label on hover
  showLabelOnHover(intersects, raycaster);
};
/* --------------------------------------------------------------------------------*/
/* clear Intersectes */
/* --------------------------------------------------------------------------------*/
const clearIntersectes = () => {
  if (INTERSECTED) {
    renderer.domElement.classList.remove("hovered");
    tooltip.visible = false;
    labelDiv.textContent = "";
  }
  INTERSECTED = null;
};
var mouseDown = false,
  mouseX = 0,
  mouseY = 0;
function onMouseMove(evt) {
  if (!mouseDown) {
    return;
  }

  evt.preventDefault();
  var deltaX = evt.clientX - mouseX,
    deltaY = evt.clientY - mouseY;
  mouseX = evt.clientX;
  mouseY = evt.clientY;
  rotateScene(deltaX, deltaY);
}

function onMouseDown(evt) {
  evt.preventDefault();
  mouseDown = true;
  mouseX = evt.clientX;
  mouseY = evt.clientY;
}

function onMouseUp(evt) {
  evt.preventDefault();

  mouseDown = false;
}

function rotateScene(deltaX, deltaY) {
  if (sceneInfo.cursor === "grab") {
    group.rotation.y += deltaX / 100;
    group.rotation.x += deltaY / 100;
    if (sceneInfo.isSplit) {
      group2.rotation.y += deltaX / 100;
      group2.rotation.x += deltaY / 100;
    }
  }
  if (sceneInfo.cursor === "move") {
    group.position.y -= deltaY / 4;
    group.position.x += deltaX / 4;
    if (sceneInfo.isSplit) {
      group2.position.y -= deltaY / 4;
      group2.position.x += deltaX / 4;
    }
  }
}
/* --------------------------------------------------------------------------------*/
/* initialize */
/* --------------------------------------------------------------------------------*/
let initialize = function (canvas, { action }, tsOptions, version, IPRs) {
  if (canvas === null) {
    return;
  }
  manifestVersion = version;
  renderer = new THREE.WebGLRenderer({
    canvas,
    alpha: true,
    antialias: true,
  });

  camera.add(light);
  camera.add(light2);
  camera2.add(light3);
  camera2.add(light4);

  scene.add(camera);

  scene2.add(camera2);

  // if (sceneInfo.isSplit) {
  //   resetPosition();
  // } else {
  //   resetPosition();
  // }

  function render() {
    let resizePOS = window.innerWidth < 840 ? 1.5 : 2; // onDesktop = 2 || on mobile = 1.5
    let resizeMOS = window.innerWidth < 840 ? -1.5 : -2; // onDesktop = -2 || on mobile = -1.5

    // if (resizeRendererToDisplaySize(renderer) ) {
    const pixelRatio = window.devicePixelRatio;
    const canvas = renderer.domElement;
    const width = sceneInfo.isSplit
      ? canvas.clientWidth / 2
      : canvas.clientWidth;
    const height = canvas.clientHeight;
    camera.left = width / resizeMOS;
    camera.right = width / resizePOS;
    camera.top = height / resizePOS;
    camera.bottom = height / resizeMOS;
    renderer.physicallyCorrectLights = true;
    renderer.setSize(
      (canvas.clientWidth * pixelRatio) | 0,
      (canvas.clientHeight * pixelRatio) | 0,
      false
    );

    camera.updateProjectionMatrix();

    if (sceneInfo.removeFirstSteps) {
      //cleanup & lpoad new meshes
      sceneInfo.firstStepMeshes.forEach((m) => group.remove(m)); //pop out
      sceneInfo.firstStepMeshes = []; //promote active to current
      sceneInfo.superImposeActive = [];
      sceneInfo.superImposeDirty = false;
      sceneInfo.removeFirstSteps = false;
    }

    if (sceneInfo.dirty) {
      let centering_group = new THREE.Group();
      //cleanup & load new meshes
      group.remove(...group.children); //pop out

      sceneInfo.currentMeshes = sceneInfo.activeMeshes; //promote active to current
      sceneInfo.activeMeshes = [];
      sceneInfo.currentMeshes.forEach((m, i) => {
        if (m.material) m.material.shininess = 0;
        else if (m.children) {
          //TODO filter for teeth only (not needed now but it would be futureproof)
          m.children.forEach((cm) => {
            cm.material.shininess = 0;
            toothNumbers.push(Number(cm.number));
          });

          sceneInfo.currentMeshes.forEach((mesh) => {
            if (
              mesh.name.startsWith("lower_") ||
              mesh.name.startsWith("upper_") ||
              mesh.name === "attachment"
            ) {
              centering_group.add(mesh.clone());
            }
            if (mesh.name === "attachment") {
              if (sceneInfo.isShowNumbers) {
                mesh.material.opacity = 0.3;
              } else mesh.material.opacity = 1;
            }
          });
          if (manifestVersion >= 2) {
            if (sceneInfo.isShowNumbers) {
              const labels = placeLabelsOnTeeth(
                m.children,
                sceneInfo.isSplit ? 0 : sceneInfo.step
              );
              labels.forEach((l) => {
                group.add(l.clone());
                sceneInfo.currentMeshes.push(l);
              });
            }
            if (sceneInfo.isIpr) {
              const iprs = placeIPRLabelsBetweenTeeth(
                m.children,
                sceneInfo.step,
                width
              );
              iprs.forEach((l) => {
                group.add(l);
                sceneInfo.currentMeshes.push(l);
              });
            }
          }
        }

        group.add(m.clone());
      }); //push in

      scene.add(group);

      if (sceneInfo.firstTime) {
        const aabb = new THREE.Box3();
        aabb.setFromObject(centering_group);
        center = new THREE.Vector3(0, 0, 0);
        aabb.getCenter(center);

        group.position.set(-center.x, -center.y, -Math.abs(center.z - 50));
        sceneInfo.firstTime = false;
      }
      sceneInfo.dirty = false;
    }
    if (sceneInfo.beforeAndAfterDirty) {
      //cleanup & load new meshes
      //sceneInfo.beforeAndAfterActive.forEach((m) => group2.remove(m)); //pop out
      group2.remove(...group2.children);
      sceneInfo.currentLastMeshes = sceneInfo.beforeAndAfterActive; //promote active to current
      sceneInfo.beforeAndAfterActive = [];
      sceneInfo.currentLastMeshes.forEach((m, i) => {
        if (m.material) m.material.shininess = 0;
        else if (m.children) {
          //TODO filter for teeth only (not needed now but it would be futureproof)
          m.children.forEach((cm) => {
            cm.material.shininess = 0;
            toothNumbers.push(Number(cm.number));
          });
          sceneInfo.currentLastMeshes.forEach((mesh) => {
            if (mesh.name === "attachment") {
              if (sceneInfo.isShowNumbers) {
                mesh.material.opacity = 0.3;
              } else mesh.material.opacity = 1;
            }
          });
          if (manifestVersion >= 2) {
            if (sceneInfo.isShowNumbers) {
              const labels = placeLabelsOnTeeth(m.children, sceneInfo.lastStep);
              labels.forEach((l) => {
                group2.add(l.clone());
                sceneInfo.currentLastMeshes.push(l);
              });
            }
          }
        }

        group2.add(m.clone());
      }); //push in
      scene2.add(group2);
      sceneInfo.beforeAndAfterDirty = false;
    }
    if (sceneInfo.superImposeDirty) {
      //cleanup & load new meshes
      sceneInfo.firstStepMeshes.forEach((m) => group.remove(m)); //pop out
      sceneInfo.firstStepMeshes = sceneInfo.superImposeActive; //promote active to current
      sceneInfo.superImposeActive = [];
      sceneInfo.firstStepMeshes.forEach((m) => {
        const transformMaterial = (m) => {
          m.material.color = { r: 0, g: 0, b: 1 };
          m.material.transparent = true;
          m.material.opacity = 0.4;
          m.material.shininess = 0;
          m.material.depthWrite = false
        };

        if (m.material) {
          transformMaterial(m);
        } else if (m.children) {
          m.children.forEach((cm) => transformMaterial(cm));
        }
        group.add(m);
      }); //push in
      sceneInfo.superImposeDirty = false;
      sceneInfo.removeFirstSteps = false;
    }

    if (sceneInfo.isSplit) {
      camera.aspect = canvas.clientWidth / 2 / canvas.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.setViewport(0, 0, canvas.clientWidth / 2, canvas.clientHeight);
      renderer.setScissor(0, 0, canvas.clientWidth / 2, canvas.clientHeight);
      renderer.setScissorTest(true);
      renderer.render(scene, camera);
      /*-----------------------------*/
      renderer.setViewport(
        canvas.clientWidth / 2,
        0,
        canvas.clientWidth / 2,
        canvas.clientHeight
      );
      renderer.setScissor(
        canvas.clientWidth / 2,
        0,
        canvas.clientWidth / 2,
        canvas.clientHeight
      );
      renderer.setScissorTest(true);
      renderer.render(scene2, camera);
    } else {
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setViewport(0, 0, canvas.clientWidth, canvas.clientHeight);
      renderer.setScissor(0, 0, canvas.clientWidth, canvas.clientHeight);
      renderer.setScissorTest(false);
      renderer.setSize(
        (canvas.clientWidth * pixelRatio) | 0,
        (canvas.clientHeight * pixelRatio) | 0,
        false
      );
      renderer.render(scene, camera);
    }
    if (sceneInfo.isGrid) {
      helperGrid.visible = true;
      helperGrid2.visible = true;
      helperGrid3.visible = true;
      helperGrid4.visible = true;
    } else {
      helperGrid.visible = false;
      helperGrid2.visible = false;
      helperGrid3.visible = false;
      helperGrid4.visible = false;
    }
    
      labelRenderer.setSize(width, height);
      labelRenderer.domElement.style.pointerEvents = "none";
      labelRenderer.domElement.style.position = "absolute";

    // append label dev to dom


    document
      .getElementById("viewer-window")
      ?.appendChild(labelRenderer.domElement);

    renderer.setClearColor(0x000000, 0);
    labelRenderer.render(scene, camera);

    requestAnimationFrame(render);
  }
  window.addEventListener("pointermove", onPointerMove);

  requestAnimationFrame(render);
};

export default {
  initializeAndRun: initialize,
  setMeshes: setMeshes,
  views: views,
  changeSceneBackgroundColor: changeSceneBackgroundColor,
  dollyIn: dollyIn,
  dollyOut: dollyOut,
  resetZoom: resetZoom,
  zoomValue: zoomValue,
  onMouseMove: onMouseMove,
  onMouseDown: onMouseDown,
  onMouseUp: onMouseUp,
  resetPosition: resetPosition,
  handleGridSize: handleGridSize,
};
