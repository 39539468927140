import * as THREE from "three";
import { mergeVertices } from "three/examples/jsm/utils/BufferGeometryUtils";
//eventually create Constants module
// const EonShapeConstants = {
//   GUMS: 1,
//   TOOTH_START: 2,
//   ATTACHMENTS_START: 45,
// };


const createGeometry = /*EonGumsGeometry*/ function (
  /*MasterFile*/ masterFile
) {
  let /*Uint32Array*/ _indices = masterFile.body.indices;
  let /*Float32Array*/ _vertices = masterFile.body.vertices;
  let /*Float32Array*/ _normals = masterFile.smoothNormals;
  let /*Float32Array*/ _colors = masterFile.colors;

  let /*JsArray<Offset>*/ offsets = masterFile.offsets;

  /* EonGumsGeometry  geometry = new EonGumsGeometry(); */
  let geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(_vertices, 3));
  geometry.setAttribute("normal", new THREE.BufferAttribute(_normals, 3));
  //geometry.setAttribute( "index", new BufferAttribute( _indices, 3 ) );
  geometry.setAttribute("color", new THREE.BufferAttribute(_colors, 3));

  //rearrange indices for the lack of drawCalls in current threejs

  if (offsets /*!= null*/) {
    let rei = new Uint32Array(_indices.length);

    for (let j = 0; j < offsets.length; j++) {
      let /*Offset*/ offset = offsets[j];
      //console.log('offset ', j, 'starting at', offset.start, 'with a shift of', offset.index)
      for (let i = 0; i < offset.count; i++) {
        rei[i + offset.start] = _indices[i + offset.start] + offset.index;
      }
    }
    geometry.setIndex(new THREE.BufferAttribute(rei, 1));
  } else {
    geometry.setIndex(new THREE.BufferAttribute(_indices, 1));
  }

  //console.log(geometry)
  return geometry;
};

let gumsCallback = function (
  decompressed,
  g_range_start,
  g_range_end,
  color_table,
  scene,
  isUpper
) {
  const p = new Promise((resolve, reject) => {
    //console.log("parsed gums", gumsParser);

    let gs = [createGeometry(decompressed)];
    let vvu ={value: new THREE.Vector3(29, 1.5, 24)}
    let vvu2 ={value: new THREE.Vector3(0, 11, 22)}
    //console.log("GS", gs)
    for (let i in gs) {
      let x = Number(i);
      let g = gs[i];

      //let material = new THREE.MeshBasicMaterial( { color: 0xffffff , side: THREE.DoubleSide} );
      g = mergeVertices(g)
      g.computeVertexNormals()
      g.computeBoundingBox()
      let uniforms = {
        bbMin: {value: g.boundingBox.min},
        bbMax: {value: g.boundingBox.max},
        color1: {value: new THREE.Color('#ffbfbf')},
        color2: {value: new THREE.Color('#913333')},
        color3: {value: new THREE.Color('#913333')},
        color4: {value: new THREE.Color('#ffbfbf')}
    }
 
    let material = new THREE.MeshLambertMaterial({
      side: THREE.DoubleSide,
      emissive: '#ff6363',
      emissiveIntensity: 0.275,
      onBeforeCompile: shader => {
  
        shader.uniforms.bbMin = uniforms.bbMin;
        shader.uniforms.bbMax = uniforms.bbMax;
        
        shader.uniforms.color1 = isUpper ? uniforms.color1 : uniforms.color3;
        shader.uniforms.color2 = isUpper ? uniforms.color2 : uniforms.color4;
        shader.vertexShader = `
            varying vec3 vPos;
          ${shader.vertexShader}
        `.replace(
        `#include <begin_vertex>`,
        `#include <begin_vertex>
        vPos = transformed;
        `
        );
        shader.fragmentShader = `
          uniform vec3 bbMin;
          uniform vec3 bbMax;
          uniform vec3 color1;
          uniform vec3 color2;
          varying vec3 vPos;
          ${shader.fragmentShader}
        `.replace(
            `vec4 diffuseColor = vec4( diffuse, opacity );`,
          `
          float f = clamp((vPos.y - bbMin.y) / (bbMax.y - bbMin.y), 0., 1.);
          vec3 col = mix(color1, color2, f);
          vec4 diffuseColor = vec4( col, opacity );`
        ); 
      },
     });
      //var material = new THREE.MeshPhongMaterial({color: 0xfafafa});
      let mesh = new THREE.Mesh(g, material);
      mesh.name = isUpper ? 'upper_gum' : 'lower_gum'
      for (let index = g_range_start; index < g_range_end; index++) {
        scene.addGums(x + index, mesh);
      }

      //console.log("gums ", i, 'at pos', (x + g_range_start))
    }
    resolve("gums ok");
  });

  return p;
};

export default { parse: gumsCallback };
