export const palmer = {
  // upper right side
  UR8: {
    palmer: "UR8",
    fdi: 18,
    universal: 1,
    index: 0,
  },

  UR7: {
    palmer: "UR7",
    fdi: 17,
    universal: 2,
    index: 1,
  },

  UR6: {
    palmer: "UR6",
    fdi: 16,
    universal: 3,
    index: 2,
  },

  UR5: {
    palmer: "UR5",
    fdi: 15,
    universal: 4,
    index: 3,
  },

  UR4: {
    palmer: "UR4",
    fdi: 14,
    universal: 5,
    index: 4,
  },

  UR3: {
    palmer: "UR3",
    fdi: 13,
    universal: 6,
    index: 5,
  },

  UR2: {
    palmer: "UR2",
    fdi: 12,
    universal: 7,
    index: 6,
  },

  UR1: {
    palmer: "UR1",
    fdi: 11,
    universal: 8,
    index: 7,
  },

  UR0: {
    palmer: "UL1",
    fdi: 21,
    universal: 9,
    index: 8,
  },
  // upper left side

  UL1: {
    palmer: "UL1",
    fdi: 21,
    universal: 9,
    index: 8,
  },

  UL2: {
    palmer: "UL2",
    fdi: 22,
    universal: 10,
    index: 9,
  },

  UL3: {
    palmer: "UL3",
    fdi: 23,
    universal: 11,
    index: 10,
  },

  UL4: {
    palmer: "UL4",
    fdi: 24,
    universal: 12,
    index: 11,
  },

  UL5: {
    palmer: "UL5",
    fdi: 25,
    universal: 13,
    index: 12,
  },

  UL6: {
    palmer: "UL6",
    fdi: 26,
    universal: 14,
    index: 13,
  },

  UL7: {
    palmer: "UL7",
    fdi: 27,
    universal: 15,
    index: 14,
  },

  UL8: {
    palmer: "UL8",
    fdi: 28,
    universal: 16,
    index: 15,
  },

  // lower left side

  LL8: {
    palmer: "LL8",
    fdi: 38,
    universal: 17,
    index: 31,
  },

  LL7: {
    palmer: "LL7",
    fdi: 37,
    universal: 18,
    index: 30,
  },

  LL6: {
    palmer: "LL6",
    fdi: 36,
    universal: 19,
    index: 29,
  },

  LL5: {
    palmer: "LL5",
    fdi: 35,
    universal: 20,
    index: 28,
  },

  LL4: {
    palmer: "LL4",
    fdi: 34,
    universal: 21,
    index: 27,
  },

  LL3: {
    palmer: "LL3",
    fdi: 33,
    universal: 22,
    index: 26,
  },

  LL2: {
    palmer: "LL2",
    fdi: 32,
    universal: 23,
    index: 25,
  },

  LL1: {
    palmer: "LL1",
    fdi: 31,
    universal: 24,
    index: 24,
  },

  // lower right side
  LR0: {
    palmer: "LL1",
    fdi: 31,
    universal: 24,
    index: 24,
  },
  LR1: {
    palmer: "LR1",
    fdi: 41,
    universal: 25,
    index: 23,
  },

  LR2: {
    palmer: "LR2",
    fdi: 42,
    universal: 26,
    index: 22,
  },

  LR3: {
    palmer: "LR3",
    fdi: 43,
    universal: 27,
    index: 21,
  },

  LR4: {
    palmer: "LR4",
    fdi: 44,
    universal: 28,
    index: 20,
  },

  LR5: {
    palmer: "LR5",
    fdi: 45,
    universal: 29,
    index: 19,
  },

  LR6: {
    palmer: "LR6",
    fdi: 46,
    universal: 30,
    index: 18,
  },

  LR7: {
    palmer: "LR7",
    fdi: 47,
    universal: 31,
    index: 17,
  },

  LR8: {
    palmer: "LR8",
    fdi: 48,
    universal: 32,
    index: 16,
  },
};
