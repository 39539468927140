import { meshesForStep } from "../../TS-VIEWER/Scene";
import viewer from "../../TS-VIEWER/viewer";

export const updateMeshesAccordingToStepNumber = function (
  currentStep = 0,
  options = {},
  startTogether,
  isMobile
) {
  const {
    showUpperArc,
    showUpper,
    showLowerArc,
    showLower,
    isSuperImpose,
    isIpr,
    isShowNumbers,
    isAttachment,
    isSplit,
    cursor,
    isGrid,
    dental_notation,
    iprData,
    points,
    isExpand
  } = options;

  const currentMeshes = meshesForStep[isSplit ? 0 : currentStep];
  const meshesForFirstStep = meshesForStep[0];
  const meshesForLastStep = meshesForStep[meshesForStep.length - 1];
  const firstStepMeshes = [];
  const lastStepMeshes = [];
  const visibleMeshes = [];

  if (meshesForStep[currentStep] === undefined) {
    return;
  } 
  else if (showUpperArc && !showLower) {
    currentMeshes[0] && visibleMeshes.push(currentMeshes[0]);
    currentMeshes[1] && visibleMeshes.push(currentMeshes[1]);
    if (isAttachment) {
      currentMeshes[2] && visibleMeshes.push(currentMeshes[2]);
    }
  }
  if (showLowerArc && !showUpper) {
    currentMeshes[3] && visibleMeshes.push(currentMeshes[3]);
    currentMeshes[4] && visibleMeshes.push(currentMeshes[4]);
    if (isAttachment) {
      currentMeshes[5] && visibleMeshes.push(currentMeshes[5]);
    }
  }

  if (showUpperArc && !showLower && isSuperImpose) {
    meshesForFirstStep[1] && firstStepMeshes.push(meshesForFirstStep[6]);
  }
  if (showLowerArc && !showUpper && isSuperImpose) {
    meshesForFirstStep[7] && firstStepMeshes.push(meshesForFirstStep[7]);
  }
  if (showUpperArc && !showLower && isSplit) {
    meshesForLastStep[0] && lastStepMeshes.push(meshesForLastStep[0]);
    meshesForLastStep[1] && lastStepMeshes.push(meshesForLastStep[1]);
    if (isAttachment) {
      meshesForLastStep[2] && lastStepMeshes.push(meshesForLastStep[2]);
    }
  }
  if (showLowerArc && !showUpper && isSplit ) {
    meshesForLastStep[3] && lastStepMeshes.push(meshesForLastStep[3]);
    meshesForLastStep[4] && lastStepMeshes.push(meshesForLastStep[4]);
    if (isAttachment) {
      meshesForLastStep[5] && lastStepMeshes.push(meshesForLastStep[5]);
    }
  }
  viewer.setMeshes(
    visibleMeshes,
    isSuperImpose,
    isIpr,
    isShowNumbers,
    firstStepMeshes,
    currentStep,
    lastStepMeshes,
    isSplit,
    cursor,
    isGrid,
    dental_notation,
    iprData,
    points,
    isExpand,
    meshesForStep.length - 1,
    startTogether,
    isMobile
  );
};
