import React, { useState, useEffect } from "react";
import viewer from "../../TS-VIEWER/viewer";

import {
  ZoomIn,
  ZoomOut,
  Attachment,
  SuperImpose,
  Left,
  Lower,
  Right,
  Upper,
  Bottom,
  Top,
  Front,
  Pan,
  Rotate,
  Numbering,
  Grid,
  Split,
  Occ,
  Ipr,
  Tools,
  Arrow,
  Expand,
  Shrink,
  Close,
} from "../svg";

export default function Nav(props) {
  const {
    isMobile,
    tsOptions,
    setTSOptions,
    isExpand,
    setExpand,
    expand,
    handle,
    handleZoomOut,
    handleZoomIn,
    width,
    isDoctor,
    isAdmin,
    zoom,
    viewerControls,
    setViewerControls,
    setZoom,
  } = props;
  const [viewActiveTs, setViewActiveTs] = useState("front");
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const zoomShortcuts = (e) => {
    if (e.key === "+") {
      handleZoomIn();
    }
    if (e.key === "-") {
      handleZoomOut();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", zoomShortcuts);
    return () => {
      window.removeEventListener("keydown", zoomShortcuts);
    };
  }, [zoom]);

  const handleExpand = () => {
    setExpand(!expand);
    if (expand === true) handle.exit();
    else handle.enter();
  };

  const onTsViewChange = (action) => {
    switch (action.viewActiveTs) {
      case "left-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("left");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
          showUpper: false,
          showLower: false,
        }));
        setZoom(100);
        viewer.resetZoom();
        viewer.views("left", tsOptions.isSplit);
        break;
      case "right-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("right");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
          showUpper: false,
          showLower: false,
        }));
        setZoom(100);
        viewer.resetZoom();
        viewer.views("right", tsOptions.isSplit);
        break;
      case "front-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("front");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
          showUpper: false,
          showLower: false,
        }));
        setZoom(100);
        viewer.resetZoom();
        viewer.views("front", tsOptions.isSplit);
        break;
      case "upper-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("upper");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: false,
          showUpper: false,
          showLower: true,
        }));
        setZoom(100);
        viewer.resetZoom();
        viewer.views("upper", tsOptions.isSplit);
        break;
      case "lower-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("lower");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: false,
          showUpperArc: true,
          showUpper: true,
          showLower: false,
        }));
        setZoom(100);
        viewer.resetZoom();
        viewer.views("lower", tsOptions.isSplit);
        break;
      case "maxilla-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return {
            ...prevOptions,
            showLower: !prevOptions.showLower,
            showUpper: false,
            showLowerArc: true,
            showUpperArc: !prevOptions.showUpperArc,
          };
        });
        setZoom(100);
        viewer.resetZoom();
        break;
      case "mandible-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return {
            ...prevOptions,
            showUpper: !prevOptions.showUpper,
            showLower: false,
            showLowerArc: !prevOptions.showLowerArc,
            showUpperArc: true,
          };
        });
        setZoom(100);
        viewer.resetZoom();
        break;
      case "attachments-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isAttachment: !prevOptions.isAttachment };
        });
        break;
      case "superImpose-view":
        if (!tsOptions.isTSViewerFound) return;
        if (tsOptions.isSplit) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isSuperImpose: !prevOptions.isSuperImpose };
        });
        break;
      case "ipr-view":
        if (!tsOptions.isTSViewerFound) return;
        if (
          tsOptions.manifestVersion === 1 ||
          Object.keys(tsOptions.iprData).length === 0
        )
          return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isIpr: !prevOptions.isIpr };
        });
        break;
      case "numbers-view":
        if (!tsOptions.isTSViewerFound) return;
        if (tsOptions.manifestVersion === 1) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isShowNumbers: !prevOptions.isShowNumbers };
        });
        break;
      // case "occlus-view":
      //   if (!tsOptions.isTSViewerFound) return;
      //   if (tsOptions.manifestVersion === 1) return;
      //   setTSOptions((prevOptions) => {
      //     return { ...prevOptions, isOcclus: !prevOptions.isOcclus };
      //   });
      //   break;
      case "split-view":
        if (!tsOptions.isTSViewerFound) return;
        if (tsOptions.isSuperImpose) return;
        setViewActiveTs("front");
        viewer.resetPosition();
        setTSOptions((prevOptions) => {
          return {
            ...prevOptions,
            isSplit: !prevOptions.isSplit,
            resetMesh: !prevOptions.resetMesh,
            isAnimateStart: !prevOptions.isAnimateStart,
            showLowerArc: true,
            showUpperArc: true,
            showUpper: false,
            showLower: false,
          };
        });
        break;
      case "grid-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isGrid: !prevOptions.isGrid };
        });
        break;
      case "rotate-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewerControls("bounty_rotate");
        setTSOptions((prevOptions) => {
          return { ...prevOptions, cursor: "grab" };
        });
        break;
      case "pan-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewerControls("bounty_pan");
        setTSOptions((prevOptions) => {
          return { ...prevOptions, cursor: "move" };
        });
        break;
      case "zoomIn-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewerControls("bounty_zoomIn");
        setTSOptions((prevOptions) => {
          return { ...prevOptions, cursor: "zoomIn" };
        });
        break;
      case "zoomOut-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewerControls("bounty_zoomOut");
        setTSOptions((prevOptions) => {
          return { ...prevOptions, cursor: "zoomOut" };
        });
        break;
      default:
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("front");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.views("front", tsOptions.isSplit);
    }
  };

  const DropDown = () => {
    return (
      <>
        {width < 720 && (
          <div
            className={`${
              isMobile
                ? "bounty_nva_options_icon_mobile"
                : "bounty_nva_options_icon"
            } ${
              tsOptions.isAttachment
                ? isMobile
                  ? "bounty_options_active_mobile"
                  : "bounty_options_active"
                : ""
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "attachments-view" });
            }}
          >
            <ToolTip text={"Show Attachment"} isOptions={true} />
            <Attachment />
            <div>Attach</div>
          </div>
        )}
        {width <= 720 && (
          <div
            className={`${
              isMobile
                ? "bounty_nva_options_icon_mobile"
                : "bounty_nva_options_icon"
            } ${
              tsOptions.manifestVersion === 2
                ? tsOptions.isIpr
                  ? isMobile
                    ? "bounty_ipr_active_mobile"
                    : "bounty_ipr_active"
                  : Object.keys(tsOptions.iprData).length > 0
                  ? ""
                  : "bounty_options_disabled"
                : "bounty_options_disabled"
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "ipr-view" });
            }}
          >
            <ToolTip text={"Show IPR"} isOptions={true} iprTooltip={true} />
            <Ipr />
            <div>IPR</div>
          </div>
        )}
        {(isDoctor || isAdmin) && (
          <div
            className={`${
              isMobile
                ? "bounty_nva_options_icon_mobile"
                : "bounty_nva_options_icon"
            } ${
              tsOptions.isSuperImpose
                ? isMobile
                  ? "bounty_options_active_mobile"
                  : "bounty_options_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "superImpose-view" });
            }}
          >
            {!tsOptions.isSplit && (
              <ToolTip text={"Show SuperImpose"} isOptions={true} />
            )}
            <div className={tsOptions.isSplit ? "bounty_options_disabled" : ""}>
              <SuperImpose />
              <div
                className={
                  isMobile
                    ? "bounty_nav_views_icon_text_mobile"
                    : "bounty_nav_views_icon_text"
                }
              >
                Super
              </div>
            </div>
          </div>
        )}
        {/* {(isDoctor || isAdmin)  && (
                <div
                  className={`${isMobile ? "bounty_nva_options_icon_mobile": "bounty_nva_options_icon"} ${
                    tsOptions.isOcclus ? isMobile ? "bounty_options_active_mobile" : "bounty_options_active" : ""
                  }`}
                  onClick={() => {
                    if (tsOptions.loading || tsOptions.isTsPrepared) return;
                    onTsViewChange({ viewActiveTs: "occlus-view" });
                  }}
                >
                  {!tsOptions.isOcclus && (
                    <ToolTip text={"Show Occlusion"} isOptions={true} />
                  )}
                  <div
                    // className={
                    //   tsOptions.isSplit ? "bounty_options_disabled" : ""
                    // }
                  >
                    
                    <Occ />
                    <div>Occlus</div>
                  </div>
                </div>
              )} */}
        {(isDoctor || isAdmin) && (
          <div
            className={`${
              isMobile
                ? "bounty_nva_options_icon_mobile"
                : "bounty_nva_options_icon"
            } ${
              tsOptions.isGrid
                ? isMobile
                  ? "bounty_options_active_mobile"
                  : "bounty_options_active"
                : ""
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "grid-view" });
            }}
          >
            <ToolTip text={"Show Grid"} isOptions={true} />
            <div>
              <Grid />
              <div
                className={
                  isMobile
                    ? "bounty_nav_views_icon_text_mobile"
                    : "bounty_nav_views_icon_text"
                }
              >
                Grid
              </div>
            </div>
          </div>
        )}

        <div
          className={`${
            isMobile
              ? "bounty_nva_options_icon_mobile"
              : "bounty_nva_options_icon"
          } ${
            tsOptions.isSplit
              ? isMobile
                ? "bounty_options_active_mobile"
                : "bounty_options_active"
              : ""
          }`}
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return;
            viewActiveTs === "split-view"
              ? onTsViewChange({ viewActiveTs: "front-view" })
              : onTsViewChange({ viewActiveTs: "split-view" });
          }}
        >
          {!tsOptions.isSuperImpose && (
            <ToolTip text={"Show Split view"} isOptions={true} />
          )}
          <div
            className={tsOptions.isSuperImpose ? "bounty_options_disabled" : ""}
          >
            <Split />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Split
            </div>
          </div>
        </div>
        {(isDoctor || isAdmin) && (
          <div
            className={`${
              isMobile
                ? "bounty_nva_options_icon_mobile"
                : "bounty_nva_options_icon"
            } ${
              tsOptions.manifestVersion === 2
                ? tsOptions.isShowNumbers
                  ? isMobile
                    ? "bounty_ipr_active_mobile"
                    : "bounty_ipr_active"
                  : ""
                : "bounty_options_disabled"
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "numbers-view" });
            }}
          >
            <ToolTip
              text={"Show Numbering"}
              isOptions={true}
              numberingTooltip={true}
            />
            <div>
              <Numbering />
              <div>Num</div>
            </div>
          </div>
        )}
      </>
    );
  };
  const ToolTip = (props) => {
    return (
      <div
        className={`bounty_tooltip_content ${
          tsOptions.manifestVersion === 1 &&
          props.iprTooltip &&
          Object.keys(tsOptions.iprData).length === 0 &&
          props.iprTooltip
            ? "bounty_tooltip_no_ipr"
            : ""
        }
        ${props.isOptions ? "bounty_tooltip_content_options" : ""}`}
      >
        {tsOptions.manifestVersion === 1 && props.numberingTooltip
          ? "Dental Notations is not available on this case"
          : tsOptions.manifestVersion === 1 && props.iprTooltip
          ? "IPR visualization is not available for this version"
          : Object.keys(tsOptions.iprData).length === 0 && props.iprTooltip
          ? "The current TS doesn't have any IPR information"
          : props.text}
      </div>
    );
  };
  return (
    <>
      <div
        className={`${
          isMobile ? "bounty_nav_container_mobile" : "bounty_nav_container"
        }`}
        style={
          tsOptions.loading || tsOptions.isTsPrepared
            ? { zIndex: "-2", display: "none" }
            : { zIndex: "2" }
        }
      >
        <div
          className={`${
            isMobile ? "bounty_nav_controls_mobile" : "bounty_nav_controls"
          }`}
        >
          <div
            className={`${
              isMobile
                ? "bounty_nav_controls_icon_mobile"
                : "bounty_nav_controls_icon"
            } ${
              viewerControls === "bounty_rotate"
                ? isMobile
                  ? "bounty_rotate_active_mobile"
                  : "bounty_rotate_active"
                : ""
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "rotate-view" });
            }}
          >
            <ToolTip text={"Rotate"} />
            <Rotate />
          </div>
          <div
            className={`bounty_nav_controls_icon ${
              viewerControls === "bounty_pan"
                ? isMobile
                  ? "bounty_pan_active_mobile"
                  : "bounty_pan_active"
                : ""
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "pan-view" });
            }}
          >
            <ToolTip text={"Pan"} />
            <Pan />
          </div>

          <div
            className={`bounty_nav_controls_icon ${
              viewerControls === "bounty_zoomOut" ? "bounty_zoomOut_active" : ""
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              handleZoomOut();
            }}
          >
            <ToolTip text={"Zoom out"} />
            <ZoomOut />
          </div>
          <div
            className={`bounty_nav_controls_icon ${
              viewerControls === "bounty_zoomIn" ? "bounty_zoomIn_active" : ""
            }`}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              handleZoomIn();
            }}
          >
            <ToolTip text={"Zoom in"} />
            <ZoomIn />
          </div>
        </div>
        <div
          className={`${
            isMobile ? "bounty_nav_views_mobile" : "bounty_nav_views"
          }`}
        >
          <div
            className={`${
              isMobile
                ? "bounty_nav_views_icon_mobile"
                : "bounty_nav_views_icon"
            } ${
              tsOptions.showLower
                ? isMobile
                  ? "bounty_nav_views_icon_active_mobile"
                  : "bounty_nav_views_icon_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "maxilla-view" });
            }}
          >
            <ToolTip text={"Show Upper"} />
            <Upper />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Upper
            </div>
          </div>
          <div
            className={`${
              isMobile
                ? "bounty_nav_views_icon_mobile"
                : "bounty_nav_views_icon"
            } ${
              viewActiveTs === "upper"
                ? isMobile
                  ? "bounty_nav_views_icon_active_mobile"
                  : "bounty_nav_views_icon_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "upper-view" });
            }}
          >
            <ToolTip text={"Top View"} />
            <Top />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Top
            </div>
          </div>
          <div
            className={`${
              isMobile
                ? "bounty_nav_views_icon_mobile"
                : "bounty_nav_views_icon"
            } ${
              viewActiveTs === "right"
                ? isMobile
                  ? "bounty_nav_views_icon_active_mobile"
                  : "bounty_nav_views_icon_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "right-view" });
            }}
          >
            <ToolTip text={"Right View"} />
            <Right />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Right
            </div>
          </div>
          <div
            className={`${
              isMobile
                ? "bounty_nav_views_icon_mobile"
                : "bounty_nav_views_icon"
            } ${
              viewActiveTs === "front"
                ? isMobile
                  ? "bounty_nav_views_icon_active_mobile"
                  : "bounty_nav_views_icon_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "front-view" });
            }}
          >
            <ToolTip text={"Front View"} />
            <Front />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Front
            </div>
          </div>
          <div
            className={`${
              isMobile
                ? "bounty_nav_views_icon_mobile"
                : "bounty_nav_views_icon"
            } ${
              viewActiveTs === "left"
                ? isMobile
                  ? "bounty_nav_views_icon_active_mobile"
                  : "bounty_nav_views_icon_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "left-view" });
            }}
          >
            <ToolTip text={"Left View"} />

            <Left />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Left
            </div>
          </div>
          <div
            className={`${
              isMobile
                ? "bounty_nav_views_icon_mobile"
                : "bounty_nav_views_icon"
            } ${
              viewActiveTs === "lower"
                ? isMobile
                  ? "bounty_nav_views_icon_active_mobile"
                  : "bounty_nav_views_icon_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "lower-view" });
            }}
          >
            <ToolTip text={"Bottom View"} />

            <Bottom />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Bottom
            </div>
          </div>

          <div
            className={`${
              isMobile
                ? "bounty_nav_views_icon_mobile"
                : "bounty_nav_views_icon"
            } ${
              tsOptions.showUpper
                ? isMobile
                  ? "bounty_nav_views_icon_active_mobile"
                  : "bounty_nav_views_icon_active"
                : ""
            } `}
            onClick={() => {
              if (tsOptions.loading || tsOptions.isTsPrepared) return;
              onTsViewChange({ viewActiveTs: "mandible-view" });
            }}
          >
            <ToolTip text={"Show Lower"} />

            <Lower />
            <div
              className={
                isMobile
                  ? "bounty_nav_views_icon_text_mobile"
                  : "bounty_nav_views_icon_text"
              }
            >
              Lower
            </div>
          </div>
        </div>
        <div
          style={width <= 720 ? { display: "hidden" } : { margin: "0px 30px" }}
          className={isMobile ? "bounty_divider_mobile" : "bounty_divider"}
        ></div>
        <div
          className={
            isMobile ? "bounty_nva_options_mobile" : "bounty_nva_options"
          }
        >
          <>
            {" "}
            {width >= 720 && !isMobile && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.isAttachment
                    ? isMobile
                      ? "bounty_options_active_mobile"
                      : "bounty_options_active"
                    : ""
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "attachments-view" });
                }}
              >
                <ToolTip text={"Show Attachment"} isOptions={true} />
                <Attachment />
                <div>Attach</div>
              </div>
            )}
            {width > 720 && !isMobile && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.manifestVersion === 2
                    ? tsOptions.isIpr
                      ? isMobile
                        ? "bounty_ipr_active_mobile"
                        : "bounty_ipr_active"
                      : Object.keys(tsOptions.iprData).length > 0
                      ? ""
                      : "bounty_options_disabled"
                    : "bounty_options_disabled"
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "ipr-view" });
                }}
              >
                {/* {console.log(
                  Object.keys(tsOptions.iprData).length,
                  tsOptions.iprData,
                  Object.keys(tsOptions.iprData).length,
                  "maaaaaaaaaaaaaaaaan"
                )} */}
                {Object.keys(tsOptions.iprData).length >= 0 && (
                  <ToolTip
                    text={"Show IPR"}
                    isOptions={true}
                    iprTooltip={true}
                  />
                )}
                <Ipr />
                <div>IPR</div>
              </div>
            )}
            {isMobile && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.isAttachment
                    ? isMobile
                      ? "bounty_options_active_mobile"
                      : "bounty_options_active"
                    : ""
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "attachments-view" });
                }}
              >
                <ToolTip text={"Show Attachment"} isOptions={true} />
                <Attachment />
                <div>Attach</div>
              </div>
            )}
            {isMobile && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.manifestVersion === 2
                    ? tsOptions.isIpr
                      ? isMobile
                        ? "bounty_ipr_active_mobile"
                        : "bounty_ipr_active"
                      : Object.keys(tsOptions.iprData).length > 0
                      ? ""
                      : "bounty_options_disabled"
                    : "bounty_options_disabled"
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "ipr-view" });
                }}
              >
                {Object.keys(tsOptions.iprData).length >= 0 && (
                  <ToolTip
                    text={"Show IPR"}
                    isOptions={true}
                    iprTooltip={true}
                  />
                )}
                <Ipr />
                <div>IPR</div>
              </div>
            )}
            {(isDoctor || isAdmin) && !isMobile && width > 839 && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.isSuperImpose
                    ? isMobile
                      ? "bounty_options_active_mobile"
                      : "bounty_options_active"
                    : ""
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "superImpose-view" });
                }}
              >
                {!tsOptions.isSplit && (
                  <ToolTip text={"Show SuperImpose"} isOptions={true} />
                )}
                <div
                  className={tsOptions.isSplit ? "bounty_options_disabled" : ""}
                >
                  <SuperImpose />
                  <div>Super</div>
                </div>
              </div>
            )}
            {/* {(isDoctor || isAdmin) && width > 766  && (
                <div
                  className={`${isMobile ? "bounty_nva_options_icon_mobile": "bounty_nva_options_icon"} ${
                    tsOptions.isOcclus ? isMobile ? "bounty_options_active_mobile" : "bounty_options_active" : ""
                  }`}
                  onClick={() => {
                    if (tsOptions.loading || tsOptions.isTsPrepared) return;
                    onTsViewChange({ viewActiveTs: "occlus-view" });
                  }}
                >
                  {!tsOptions.isSplit && (
                    <ToolTip text={"Show Occlusion"} isOptions={true} />
                  )}
                  <div
                    // className={
                    //   tsOptions.isSplit ? "bounty_options_disabled" : ""
                    // }
                  >
                    
                    <Occ />
                    <div>Occlus</div>
                  </div>
                </div>
              )} */}
            {(isDoctor || isAdmin) && !isMobile && width > 839 && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.isGrid
                    ? isMobile
                      ? "bounty_options_active_mobile"
                      : "bounty_options_active"
                    : ""
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "grid-view" });
                }}
              >
                <ToolTip text={"Show Grid"} isOptions={true} />
                <Grid />
                <div>Grid</div>
              </div>
            )}
            {(isDoctor || isAdmin) && !isMobile && width > 839 && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.isSplit
                    ? isMobile
                      ? "bounty_options_active_mobile"
                      : "bounty_options_active"
                    : ""
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "split-view" });
                }}
              >
                {!tsOptions.isSuperImpose && (
                  <ToolTip text={"Show Split view"} isOptions={true} />
                )}
                <div
                  className={
                    tsOptions.isSuperImpose ? "bounty_options_disabled" : ""
                  }
                >
                  <Split />
                  <div>Split</div>
                </div>
              </div>
            )}
            {(isDoctor || isAdmin) && !isMobile && width > 839 && (
              <div
                className={`${
                  isMobile
                    ? "bounty_nva_options_icon_mobile"
                    : "bounty_nva_options_icon"
                } ${
                  tsOptions.manifestVersion === 2
                    ? tsOptions.isShowNumbers
                      ? isMobile
                        ? "bounty_ipr_active_mobile"
                        : "bounty_ipr_active"
                      : ""
                    : "bounty_options_disabled"
                }`}
                onClick={() => {
                  if (tsOptions.loading || tsOptions.isTsPrepared) return;
                  onTsViewChange({ viewActiveTs: "numbers-view" });
                }}
              >
                <ToolTip
                  text={"Show Numbering"}
                  isOptions={true}
                  numberingTooltip={true}
                />
                <Numbering />
                <div>Num</div>
              </div>
            )}
          </>
          {!isMobile && width <= 839 && (
            <div
              className="bounty_dropdown bounty_tools_icon"
              onClick={() => setDropDownOpen(() => !dropDownOpen)}
            >
              <div className="bounty_tools_div">
                <Tools />
                <div className="bounty_tools_arrow">
                  <Arrow />
                </div>
              </div>
              {dropDownOpen && (
                <div className="bounty_dropdown_content">
                  <DropDown />
                </div>
              )}
              <div>Tools</div>
            </div>
          )}
        </div>
        {isExpand && !isMobile && (
          <span
            onClick={() => handleExpand()}
            className={"bounty_nav_icon_full_screen"}
          >
            {!expand ? <Expand /> : <Shrink />}
          </span>
        )}
        <div className="bounty_canvas_container">
          {tsOptions.isSplit && (
            <div className="bounty_initial_plan">Initial Plan</div>
          )}
          {tsOptions.isSplit && (
            <div className="bounty_final_result">Final Result</div>
          )}
          {tsOptions.isSplit && (
            <div
              className="bounty_close_split"
              onClick={() => {
                onTsViewChange({ viewActiveTs: "split-view" });
              }}
            >
              <Close />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
