import { findNumber } from "./findNumbers";

export const convertObjectToArray = (obj) => {
  let arr = Object.keys(obj).map((key) => {
    return { step: key, amount: obj[key] };
  });

  return arr;
};

export function mapMFGTeethIPR(mfgData) {
  const teeth = mfgData?.options?.teeth || {};
  const iprPosition = mfgData?.options?.iprPosition || {};

  const newData = {};
  let RightTeethUpper = mapRightTeeth(
    newData,
    "upper_r_",
    "upper_teeth",
    "R",
    "upper_teeth",
    18,
    false,
    "UR",
    teeth,
    iprPosition
  );
  let RightTeethLower = mapRightTeeth(
    newData,
    "lower_r_",
    "lower_teeth",
    "R",
    "lower_teeth",
    48,
    false,
    "LR",
    teeth,
    iprPosition
  );

  let LeftTeethUpper = mapLeftTeeth(
    newData,
    "upper_l_",
    "upper_teeth",
    "L",
    "upper_teeth",
    21,
    true,
    "UL",
    teeth,
    iprPosition
  );
  let LeftTeethLower = mapLeftTeeth(
    newData,
    "lower_l_",
    "lower_teeth",
    "L",
    "lower_teeth",
    31,
    true,
    "LL",
    teeth,
    iprPosition
  );

  let Groupedteeth = {
    upper_teeth: { ...RightTeethUpper, ...LeftTeethUpper },
    lower_teeth: { ...RightTeethLower, ...LeftTeethLower },
  };
  return Groupedteeth;
}

function mapRightTeeth(
  newData,
  key,
  teeth,
  side,
  position,
  counter,
  increment,
  position2,
  mfgteeth,
  iprPosition
) {
  let num = 8;
  let count = counter;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    const uniTooth = findNumber("palmer", `${position2}${num}`)["index"];
    const ipr_steps_amounts = iprPosition[uniTooth]
      ? convertObjectToArray(iprPosition[uniTooth])
      : [];
    const restrict_ipr_tooth = mfgteeth[uniTooth];
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
      
        updated: false,
        index: num,
        amount: "",
        before_step: "",
        divider: true,
        use_ipr: ipr_steps_amounts.length > 0 ? true : false,
        do_not_perform_ipr: restrict_ipr_tooth || false,
        fdi_key: increment ? count + 1 : count - 1,
        tooth_key: `${position2}_${num} / ${position2}_${num - 1}`,
        ipr_steps_amounts:
          ipr_steps_amounts.length > 0
            ? ipr_steps_amounts
            : [{ step: 0, amount: 0 }],
        restrict_ipr_tooth: restrict_ipr_tooth || false,
        tooth_key_sys: {
          palmer: `${findNumber("palmer", `${position2}${num}`)["palmer"]} / ${
            findNumber("palmer", `${position2}${num - 1}`)["palmer"]
          }`,
          fdi: `${findNumber("palmer", `${position2}${num}`)["fdi"]} / ${
            findNumber("palmer", `${position2}${num - 1}`)["fdi"]
          }`,
          universal: `${
            findNumber("palmer", `${position2}${num}`)["universal"]
          } / ${findNumber("palmer", `${position2}${num - 1}`)["universal"]}`,
        },
        ToothSystemName: findNumber("palmer", `${position2}${num}`)["index"],
      },
    };
    num--;
  }
  return rightTeeth;
}

function mapLeftTeeth(
  newData,
  key,
  teeth,
  side,
  position,
  counter,
  increment,
  position2,
  mfgteeth,
  iprPosition
) {
  let num = 1;
  let count = counter;
  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    const uniTooth = findNumber("palmer", `${position2}${num}`)["index"];

    const ipr_steps_amounts = iprPosition[uniTooth]
      ? convertObjectToArray(iprPosition[uniTooth])
      : [];
    const restrict_ipr_tooth = mfgteeth[uniTooth];
    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]: {
        updated: false,
   
        ...(x < 8 && {
          amount: "",
          before_step: "",
          divider: true,
          use_ipr: ipr_steps_amounts.length > 0 ? true : false,
          do_not_perform_ipr: restrict_ipr_tooth || false,
          tooth_key_sys: {
            palmer: `${findNumber("palmer", `${position2}${num}`)?.palmer} / ${
              findNumber("palmer", `${position2}${num + 1}`)?.palmer
            }`,
            fdi: `${findNumber("palmer", `${position2}${num}`).fdi} / ${
              findNumber("palmer", `${position2}${num + 1}`)?.fdi
            }`,
            universal: `${
              findNumber("palmer", `${position2}${num}`)?.universal
            } / ${findNumber("palmer", `${position2}${num + 1}`)?.universal}`,
          },
          tooth_key: `${position2}_${num} / ${position2}_${num + 1}`,
          ipr_steps_amounts:
            ipr_steps_amounts.length > 0
              ? ipr_steps_amounts
              : [{ step: 0, amount: 0 }],
          restrict_ipr_tooth: restrict_ipr_tooth || false,
        }),
        fdi_key: increment ? count + 1 : count - 1,
        ToothSystemName: findNumber("palmer", `${position2}${num}`)["index"],
      },
    };
    num++;
  }
  return leftTeeth;
}
