import { fdi } from "./fdi";
import { universal } from "./universal";
import { palmer } from "./palmer";

export function findNumber(system, tooth) {
    switch (system) {
        case "fdi":
            return fdi[tooth];
        case "universal":
            return universal[tooth];
        case "palmer":
            return palmer[tooth];
        default:
            return fdi[tooth];
    }
}




